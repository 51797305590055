import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SocketContext } from "../../context/socket";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { useTranslation } from "react-i18next";

let selectedTask;

export default function TypingStatus() {
  const { t } = useTranslation();
  const name = useSelector((state) => state.login.name);
  const task = useSelector((state) => state.current);
  const [isTyping, setIsTyping] = useState(false);
  const [flag, setFlag] = useState(false);
  const socket = useContext(SocketContext);
  const [typistName, setTypistName] = useState("");

  // useEffect(() => {
  //   if (users.length > 0) setIsTyping(true);
  //   else setIsTyping(false);
  //   setFlag(!flag);
  // }, [users]);

  useEffect(() => {
    selectedTask = task;
    setIsTyping(false);
  }, [task]);

  useEffect(() => {
    const userInfo = getUserFromLocalStorage();
    socket.on("typing", ({ room, user }) => {
      if (userInfo._id != user._id && room == selectedTask._id) {
        setTypistName(user.username);
        setIsTyping(true);
      } else {
        // console.log(userInfo._id, user._id, room, selectedTask._id);
      }
    });

    socket.on("stop-typing", ({ room, user }) => {
      if (userInfo._id != user._id && room == selectedTask._id) {
        setTypistName(user.username);
        setIsTyping(false);
      }
    });

    return () => {
      socket.removeListener("typing");
      socket.removeListener("stop-typing");
    };
  }, []);

  return (
    <div className="typing d-flex m-2 align-items-center">
      <div className="is-typing">
        {/* {isTyping
          ? users.length > 1
            ? "Several users is typing"
            : users[0] + " is typing "
          : ""} */}
        {isTyping ? `${typistName} ${t("is_typing")}` : " "}
      </div>
      {isTyping ? <div className="dot-elastic"></div> : <></>}
    </div>
  );
}
