import React, { useEffect, useContext, useState } from "react";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteCurrentTask, setCurrentTask } from "../../redux/currentSlice";
import {
  addEstimate,
  addConfirm,
  addAssign,
  addComplete,
  addPay,
  addTask,
  deleteTask,
  removeUnread,
  addChangeOffer,
  submitRateTask,
  newMessage,
  addOthersUnread,
} from "../../redux/taskSlice";
import moment from "moment";
import { SocketContext } from "../../context/socket";
import api from "../../utils/axios";
import {
  API_URL,
  STATUS,
  ROLE,
  OFFER,
  ADMIN_NAME,
  DEADLINE,
  LANGUAGE,
} from "../../constants/constants";
import { toast } from "react-toastify";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { changeBalance } from "../../redux/loginSlice";
import TeamAvatar from "../../assets/images/team.png";
import { useTranslation } from "react-i18next";
import { addMessage } from "../../redux/messageSlice";

export default function Task({ task }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const currentTask = useSelector((state) => state.current);
  const [taskId, setTaskId] = useState();
  const [taskname, setTaskName] = useState(task.taskname);
  const [percent, setPercent] = useState(task.percent);

  const [deadline, setDeadline] = useState(task.deadline);
  const [duedate, setDuedate] = useState(task.duedate);
  const [developer, setDeveloper] = useState(
    task.developer
      ? task.developer
      : {
        username: "PE",
        avatar:
          "https://res.cloudinary.com/dbjfuowqq/image/upload/v1667245571/wp-sofa-avatar/avatar_kuuspt.png",
      }
  );

  const [client, setClient] = useState(task.client);
  const [changeDeveloper, setChangeDeveloper] = useState(0);

  const [assigneddeveloper, setAssignedDeveloper] = useState(
    task.developer ? task.developer.username : "PE"
  );
  const [priority, setPriority] = useState(task.priority);
  const [createdtime, setCreatedtime] = useState(task.createdAt);
  const [status, setStatus] = useState(task.status);
  const [hourlyrate, setHourlyrate] = useState(task.hourlyrate);
  const [fasthourlyrate, setFasthourlyrate] = useState(task.fasthourlyrate);
  const [fastduedate, setFastduedate] = useState(task.fastduedate);
  const [workeffort, setWorkeffort] = useState(task.workeffort);
  const [selectedoffer, setSelectedoffer] = useState(task.selectedoffer);
  const [cost, setCost] = useState(task.cost);
  const [estimateShow, setEstimateShow] = useState(true);
  const [changeofferShow, setChangeofferShow] = useState(false);
  const [assignShow, setAssignShow] = useState(true);
  const [rating, setRating] = useState(task.rating);
  const [hoverRating, setHoverRating] = useState(0);

  const [duringdays, setDuringDays] = useState(task.duringdays);
  const [fastduringdays, setFastDuringDays] = useState(task.fastduringdays);

  // Change offer states
  const [hourlyrate_change, setHourlyrateChange] = useState(task.hourlyrate);
  const [workeffort_change, setWorkeffortChange] = useState(task.workeffort);
  const [duedate_change, setDuedateChange] = useState(task.duedate);
  const [fasthourlyrate_change, setFasthourlyrateChange] = useState(
    task.fasthourlyrate
  );
  const [fastduedate_change, setFastduedateChange] = useState(task.fastduedate);

  const [user, setUser] = useState({});
  const [unread, setUnread] = useState(0);
  const [devList, setDevList] = useState([]);

  const [devs, setDevs] = useState([]);
  const [person, setPerson] = useState({});
  const [latestMessage, setLatestMessage] = useState(task.latestMessage);
  const [expended, setExpended] = useState(false);
  const [comment, setComment] = useState(task.comment);

  const statusList = [
    { text: "Created", class: "created" },
    { text: "Estimated", class: "estimated" },
    { text: "Confirmed", class: "confirmed" },
    { text: "In Progress", class: "inprogress" },
    { text: "Completed", class: "completed" },
    { text: "Paid", class: "paid" },
    { text: "", class: "" },
    { text: "", class: "" },
    { text: "Get feedback", class: "getfeedback" },
  ];

  useEffect(() => {
    const user = getUserFromLocalStorage();
    const users = task.users;
    const tempPerson = users?.filter((item) => item._id != user._id);
    setPerson(tempPerson?.[0]);
  }, []);

  // Get developer list
  useEffect(() => {
    const user = getUserFromLocalStorage();
    setUser(user);
    if (user.role == ROLE.ADMIN)
      api
        .get(`${API_URL}/api/v1/user/dev`)
        .then((res) => {
          const data = res.data;
          setDevs([...data]);
        })
        .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    // Set User
    const user = getUserFromLocalStorage();
    setUser(user);

    // Set Task states
    setTaskId(task._id);
    setDeadline(task.deadline);
    setDeveloper(
      task.developer
        ? task.developer
        : {
          username: "PE",
          avatar:
            "https://res.cloudinary.com/dbjfuowqq/image/upload/v1667245571/wp-sofa-avatar/avatar_kuuspt.png",
        }
    );
    setTaskName(task.taskname);
    setPercent(task.percent);
    setPriority(task.priority);
    setCreatedtime(task.createdAt);
    setStatus(task.status);
    setWorkeffort(task.workeffort);
    setHourlyrate(task.hourlyrate);
    setFasthourlyrate(task.fasthourlyrate);
    setSelectedoffer(task.selectedoffer);
    setCost(task.cost);
    setWorkeffortChange(task.workeffort);
    setHourlyrateChange(task.hourlyrate);
    setFasthourlyrateChange(task.fasthourlyrate);
    setRating(task.rating);
    setDuringDays(task.duringdays);
    setFastDuringDays(task.fastduringdays);

    if (task.duringdays >= 0 && task.status < STATUS.CONFIRMED) {
      console.log(new Date().getTime() + 1000 * 3600 * 24 * task.duringdays);
      setDuedate(new Date().getTime() + 1000 * 3600 * 24 * task.duringdays);
      setDuedateChange(
        new Date().getTime() + 1000 * 3600 * 24 * task.duringdays
      );
    } else {
      setDuedate(new Date(task.duedate));
      setDuedateChange(new Date(task.duedate));
    }
    if (task.fastduringdays >= 0 && task.status < STATUS.CONFIRMED) {
      setFastduedate(
        new Date().getTime() + 1000 * 3600 * 24 * task.fastduringdays
      );
      setFastduedateChange(
        new Date().getTime() + 1000 * 3600 * 24 * task.fastduringdays
      );
    } else {
      setFastduedate(new Date(task.fastduedate));
      setFastduedateChange(new Date(task.fastduedate));
    }

    const allUnread = task.unread;
    const myUnread = allUnread?.filter((item) => item.user == user._id);
    setUnread(myUnread?.[0]?.number);
  }, [task]);

  const onSelect = async () => {
    if (task._id == currentTask._id) return;

    dispatch(setCurrentTask(task));
    dispatch(removeUnread({ taskId: task._id, userId: user._id }));
    socket.emit("message-read", { task: task, userId: user._id });

    // await api
    //   .post(`${API_URL}/api/v1/task/read`, {
    //     taskId: task._id,
    //   })
    //   .then((res) => {
    //     dispatch(removeUnread({ task: task, user: user }));
    //   })
    //   .catch((err) => {
    //     if (err.response.status == 401) {
    //       navigate("/");
    //     }
    //     const errors = err.response.data.errors;
    //     errors.forEach((error) => toast.error(error.msg));
    //   });
  };

  const onEstimate = async () => {
    const duringdays = getDays(duedate);
    const fastduringdays = getDays(fastduedate);

    await api
      .post(`${API_URL}/api/v1/task/estimate`, {
        taskId,
        workeffort,
        hourlyrate,
        duedate,
        fasthourlyrate,
        fastduedate,
        duringdays,
        fastduringdays,
      })
      .then((res) => {
        const data = res.data;

        dispatch(addEstimate(data));
        socket.emit("new-estimate", data);
        sendMessage(`Hi ${data.client.username}, I estimated the task`);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => {
          toast.error(error.msg);
        });
      });
  };

  const onChangeOffer = async () => {
    const duringdays = getDays(duedate_change);
    const fastduringdays = getDays(fastduedate_change);

    await api
      .post(`${API_URL}/api/v1/task/changeoffer`, {
        taskId,
        workeffort: workeffort_change,
        hourlyrate: hourlyrate_change,
        duedate: duedate_change,
        fasthourlyrate: fasthourlyrate_change,
        fastduedate: fastduedate_change,
        duringdays,
        fastduringdays,
      })
      .then((res) => {
        setChangeofferShow(false);
        const data = res.data;
        dispatch(addChangeOffer(data));
        socket.emit("new-changeoffer", data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => {
          toast.error(error.msg);
        });
      });
  };

  const onChangePriority = async (priority) => { };

  const onConfirm = async () => {
    const user = getUserFromLocalStorage();
    const balance = user.balance;
    const cost =
      selectedoffer == OFFER.NORMAL
        ? workeffort * hourlyrate
        : workeffort * fasthourlyrate;

    if (balance + 500 < cost) {
      toast.warning(
        `Hi ${user.username}, plz add money to your account (billing) to confirm that task...`
      );
    } else {
      const duedate = new Date().getTime() + 24 * 3600 * 1000 * duringdays;
      const fastduedate =
        new Date().getTime() + 24 * 3600 * 1000 * fastduringdays;
      await api
        .post(`${API_URL}/api/v1/task/confirm`, {
          taskId,
          selectedoffer,
          duedate,
          fastduedate,
          cost,
        })
        .then((res) => {
          const data = res.data;

          dispatch(addConfirm(data));
          socket.emit("new-confirm", data);

          let message;
          if (client.language == LANGUAGE.EN) {
            message = `Hi ${ADMIN_NAME}, I just confirmed the task`;
          } else if (client.language == LANGUAGE.DE)
            message = `Hallo ${ADMIN_NAME}, ich habe gerade die Aufgabe bestätigt`;
          else if (client.language == LANGUAGE.ES)
            message = `Hi ${ADMIN_NAME}, I just confirmed the task`;

          sendMessage(message);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    }
  };

  const onAssign = async () => {
    await api
      .post(`${API_URL}/api/v1/task/assign`, {
        taskId,
        developer: devList[changeDeveloper]._id,
      })
      .then((res) => {
        const data = res.data;

        dispatch(addAssign({ task: data, role: user.role }));
        socket.emit("new-assign", data);
        sendMessage(
          `Hi ${data.client.username},I just moved the task into working.`
        );
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onComplete = async () => {
    await api
      .post(`${API_URL}/api/v1/task/complete`, {
        taskId,
      })
      .then((res) => {
        const data = res.data;

        dispatch(addComplete(data));
        socket.emit("new-complete", data);
        sendMessage(
          `Hi ${data.client.username}, I just finished the task, can you please check?`
        );
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onPay = async () => {
    await api
      .post(`${API_URL}/api/v1/task/pay`, {
        taskId,
      })
      .then((res) => {
        const data = res.data;
        const { cost } = data;
        dispatch(addPay(data));
        dispatch(changeBalance(-1 * cost));
        socket.emit("new-pay", data);

        let message;
        if (client.language == LANGUAGE.EN) {
          message = `Hi ${ADMIN_NAME}, I just paid for the task.`;
        } else if (client.language == LANGUAGE.DE)
          message = `Hallo ${ADMIN_NAME}, ich habe gerade für die Aufgabe bezahlt.`;
        else if (client.language == LANGUAGE.ES)
          message = `Hi ${ADMIN_NAME}, I just paid for the task.`;

        sendMessage(message);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onDelete = async () => {
    await api
      .post(`${API_URL}/api/v1/task/delete`, {
        taskId,
      })
      .then((res) => {
        const task = res.data;
        const taskId = task._id;
        dispatch(deleteCurrentTask());
        dispatch(deleteTask(taskId));
        socket.emit("delete-task", task);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onPaidDelete = async () => {
    await api
      .post(`${API_URL}/api/v1/task/paiddelete`, {
        taskId,
      })
      .then((res) => {
        const task = res.data;
        const taskId = task._id;
        dispatch(deleteCurrentTask());
        dispatch(deleteTask(taskId));
        socket.emit("delete-task", task);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const onSubmitRating = async () => {
    if (rating == 0) {
      toast.warning("No stars are selected");
      return;
    }

    await api
      .post(`${API_URL}/api/v1/task/submitrating`, {
        taskId,
        rating,
        comment,
      })
      .then((res) => {
        const task = res.data;
        dispatch(submitRateTask(task));
        socket.emit("new-submitrate", task);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const getDays = (date) => {
    let difference = new Date(date).getTime() - new Date().getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };
  // Get developer list
  const getDeveloper = () => {
    const user = getUserFromLocalStorage();
    setUser(user);
    if (user.role === ROLE.ADMIN && task.status === STATUS.CONFIRMED)
      api
        .get(`${API_URL}/api/v1/user/dev`)
        .then((res) => {
          const data = res.data;
          setDevList([...data]);
        })
        .catch((err) => console.log(err));
  };

  const sendMessage = async (message) => {
    const user = getUserFromLocalStorage();

    // dispatch(
    //   addMessage({
    //     sender: user,
    //     message: message,
    //     images: [],
    //     files: [],
    //     task: task._id,
    //     createdAt: new Date(),
    //   })
    // );
    // dispatch(newMessage({ taskId: task._id, message: "" }));
    // dispatch(
    //   addOthersUnread({
    //     taskId: task._id,
    //     userId: user._id,
    //     senderId: user._id,
    //   })
    // );

    await api
      .post(`${API_URL}/api/v1/message/send/`, {
        message: message,
        images: [],
        files: [],
        taskId: task._id,
      })
      .then((res) => {
        dispatch(addMessage(res.data));
        dispatch(newMessage({ taskId: task._id, message: res.data.message }));

        dispatch(
          addOthersUnread({
            taskId: task._id,
            userId: user._id,
            senderId: user._id,
          })
        );

        // After store message, emit message sent event
        socket.emit("new-message", res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }

        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  return (
    <div className="task mt-3">
      <div className="d-flex justify-content-between " onClick={onSelect}>
        <div>
          <img className="client-avatar" src={person?.avatar} />
        </div>
        <div className="flex-auto">
          <div className="d-flex justify-content-between align-items-center">
            <div className="title">{taskname}</div>
            <div className={`status ${statusList[status] ? statusList[status].class : ''}`}>
              {statusList[status] ? statusList[status].text : ''}
            </div>
          </div>

          <div className="text">
            {latestMessage ? latestMessage.message : ""}
          </div>
        </div>
        <div className="ms-2">
          <div className="latest-message-date">
            {task && task.updatedTime && moment(task.updatedTime).isValid()
              ? new Date(task.updatedTime).toLocaleDateString()
              : ""}
          </div>
          {/* <div className="unread-messsages mt-2">6</div> */}
          {unread &&
            unread > 0 &&
            (!task.latestMessage || task.latestMessage.sender != user._id) ? (
            <div className="unread-messsages mt-2">{unread}</div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* {user.role == ROLE.CLIENT ? (
        <div
          className="text-center expend-button"
          onClick={() => setExpended(!expended)}
        >
          {expended ? (
            <i class="bi bi-chevron-compact-up"></i>
          ) : (
            <i class="bi bi-chevron-compact-down"></i>
          )}
        </div>
      ) : (
        <div style={{ paddingBottom: "20px" }}></div>
      )} */}
      <div
        className="text-center expend-button"
        onClick={() => setExpended(!expended)}
      >
        {expended ? (
          <i class="bi bi-chevron-compact-up"></i>
        ) : (
          <i class="bi bi-chevron-compact-down"></i>
        )}
      </div>
      {expended && (
        <div className="p-2" style={{ borderTop: "1px solid #707c97" }}>
          {status == STATUS.CREATED && (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="sub-title">{t("priority")}: </div>
                  <div className="sub-content">
                    <button className="priority-button">{priority}</button>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {moment(createdtime).fromNow()}
                </div>
                <button className="delete-task-button" onClick={onDelete}>
                  <i className="bi bi-trash-fill"></i>
                </button>
              </div>
            </>
          )}
          {status == STATUS.ESTIMATED && user.role == ROLE.CLIENT && (
            <>
              <div className="d-flex mt-2 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="sub-title">{t("priority")}: </div>
                  <div className="sub-content">
                    <button className="priority-button">{priority}</button>
                  </div>
                </div>
                <button className="delete-task-button" onClick={onDelete}>
                  <i className="bi bi-trash-fill"></i>
                </button>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("work_effort")}: </div>
                <div className="sub-content">
                  {workeffort} {t("hours")}
                </div>
              </div>
              <div className="row mt-2 justify-content-center">
                <div
                  className={`col-5 text-center`}
                  onClick={() => {
                    setSelectedoffer(OFFER.NORMAL);
                  }}
                >
                  <div
                    className={` ${selectedoffer == OFFER.NORMAL
                      ? "normal-offer-card-active"
                      : "normal-offer-card"
                      }`}
                  >
                    {t("normal")}&nbsp;/&nbsp;
                    {hourlyrate * workeffort == 0
                      ? "FREE"
                      : `$${hourlyrate * workeffort}`}
                  </div>
                  <div className="due-date">
                    Due: {new Date(duedate).toLocaleDateString()}
                  </div>
                </div>
                <div className="col-1"></div>
                <div
                  className={`col-5 text-center `}
                  onClick={() => {
                    setSelectedoffer(OFFER.FAST);
                  }}
                >
                  <div
                    className={`${selectedoffer == OFFER.FAST
                      ? "fast-offer-card-active"
                      : "fast-offer-card"
                      }`}
                  >
                    <i className="bi bi-lightning-fill"></i> {t("fast")}
                    &nbsp;/&nbsp;
                    {fasthourlyrate * workeffort == 0
                      ? "FREE"
                      : `$${fasthourlyrate * workeffort}`}
                  </div>
                  <div className="due-date">
                    Due: {new Date(fastduedate).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-2">
                <button className="estimate-button" onClick={onConfirm}>
                  {t("confirm")}
                </button>
              </div>
            </>
          )}
          {status == STATUS.ESTIMATED && user.role == ROLE.ADMIN && (
            <>
              <div className="d-flex mt-2 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="sub-title">{t("priority")}: </div>
                  <div className="sub-content">
                    <button className="priority-button">{priority}</button>
                  </div>
                </div>
                <button className="delete-task-button" onClick={onDelete}>
                  <i className="bi bi-trash-fill"></i>
                </button>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("work_effort")}: </div>
                <div className="sub-content">
                  {workeffort} {t("hours")}
                </div>
              </div>
              <div className="row mt-2 justify-content-center">
                <div
                  className={`col-5 text-center`}
                >
                  <div
                    className={` ${selectedoffer == OFFER.NORMAL
                      ? "normal-offer-card-active"
                      : "normal-offer-card"
                      }`}
                  >
                    {t("normal")}&nbsp;/&nbsp;
                    {hourlyrate * workeffort == 0
                      ? "FREE"
                      : `$${hourlyrate * workeffort}`}
                  </div>
                  <div className="due-date">
                    Due: {new Date(duedate).toLocaleDateString()}
                  </div>
                </div>
                <div className="col-1"></div>
                <div
                  className={`col-5 text-center `}
                >
                  <div
                    className={`${selectedoffer == OFFER.FAST
                      ? "fast-offer-card-active"
                      : "fast-offer-card"
                      }`}
                  >
                    <i className="bi bi-lightning-fill"></i> {t("fast")}
                    &nbsp;/&nbsp;
                    {fasthourlyrate * workeffort == 0
                      ? "FREE"
                      : `$${fasthourlyrate * workeffort}`}
                  </div>
                  <div className="due-date">
                    Due: {new Date(fastduedate).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </>
          )}
          {status == STATUS.CONFIRMED && (
            <>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("cost")}: </div>
                <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                  })`}
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("due_date")}: </div>
                <div className="sub-content">
                  {selectedoffer == OFFER.NORMAL
                    ? new Date(duedate).toLocaleDateString()
                    : new Date(fastduedate).toLocaleDateString()}
                </div>
              </div>
            </>
          )}
          {status == STATUS.ASSIGNED && (
            <>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("cost")}: </div>
                <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? t("hours") : t("hour")
                  })`}</div>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("due_date")}: </div>
                <div className="sub-content">
                  {selectedoffer == OFFER.NORMAL
                    ? new Date(duedate).toLocaleDateString()
                    : new Date(fastduedate).toLocaleDateString()}
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("assigned")}:</div>
                <div className="sub-content">{developer.username}</div>
                <div>
                  <img
                    className="assigned-developer-avatar"
                    src={developer.avatar}
                  />
                  {/* <Avatar
                    className="assigned-developer-avatar"
                    name={assigneddeveloper}
                    round={true}
                    size="24px"
                  /> */}
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("in_progress")} </div>
                <div className="sub-content d-flex align-items-center">
                  <div className="dot-typing"></div>
                </div>
              </div>
              <div className="mt-2">
                <input className="w-100" type="range" value={percent} />
              </div>
            </>
          )}
          {status == STATUS.COMPLETED && (
            <>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("cost")}: </div>
                <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                  })`}</div>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("due_date")}: </div>
                <div className="sub-content">
                  {selectedoffer == OFFER.NORMAL
                    ? new Date(duedate).toLocaleDateString()
                    : new Date(fastduedate).toLocaleDateString()}
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("done")} </div>
                <div className="sub-content d-flex align-items-center">
                  <div className="check-mark">
                    <i className="bi bi-check-circle-fill"></i>
                  </div>
                </div>
              </div>
              {
                user.role == ROLE.CLIENT && <div className="d-flex mt-2">
                  <button className="estimate-button" onClick={(e) => onPay()}>
                    {t("confirm_pay")}
                  </button>
                </div>
              }
            </>
          )}
          {status == STATUS.PAID ? (
            user.role == ROLE.CLIENT ? (
              <>
                <div className="d-flex mt-2">
                  <div className="sub-title">{t("due_date")}: </div>
                  <div className="sub-content">
                    {selectedoffer == OFFER.NORMAL
                      ? new Date(duedate).toLocaleDateString()
                      : new Date(fastduedate).toLocaleDateString()}
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <div className="sub-title">{t("done_paid")}</div>
                  <div className="sub-content d-flex align-items-center">
                    <div className="check-mark">
                      <i className="bi bi-check-circle-fill"></i>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <div className="sub-title">{t("feedback")} </div>
                  <div className="sub-content">
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <button
                          key={index}
                          className={
                            index <= (hoverRating || rating)
                              ? "star-button-on"
                              : "star-button-off"
                          }
                          onClick={() => setRating(index)}
                          onMouseEnter={() => setHoverRating(index)}
                          onMouseLeave={() => setHoverRating(rating)}
                        >
                          <i class="bi bi-star-fill"></i>
                        </button>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex mt-2 justidy-content-between">
                  <div className="sub-title">{t("comment")} </div>
                  <div className="sub-content w-100">
                    <textarea
                      value={comment}
                      className="w-100 p-2"
                      rows="3"
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <button className="estimate-button" onClick={onSubmitRating}>
                    {t("submit")}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex mt-2">
                  <div className="sub-title">{t("cost")}: </div>
                  <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                    })`}</div>
                </div>
                <div className="d-flex mt-2">
                  <div className="sub-title">{t("due_date")}: </div>
                  <div className="sub-content">
                    {selectedoffer == OFFER.NORMAL
                      ? new Date(duedate).toLocaleDateString()
                      : new Date(fastduedate).toLocaleDateString()}
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <div className="sub-title">{t("done_paid")}</div>
                  <div className="sub-content d-flex align-items-center">
                    <div className="check-mark">
                      <i className="bi bi-check-circle-fill"></i>
                    </div>
                  </div>
                </div>
              </>
            )
          ) : <></>
          }
          {status == STATUS.GETFEEDBACK && (
            <>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("cost")}: </div>
                <div className="sub-content">{`$${cost} (${workeffort} ${workeffort > 1 ? "hours" : "hour"
                  })`}</div>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("due_date")}: </div>
                <div className="sub-content">
                  {selectedoffer == OFFER.NORMAL
                    ? new Date(duedate).toLocaleDateString()
                    : new Date(fastduedate).toLocaleDateString()}
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("done_paid")}</div>
                <div className="sub-content d-flex align-items-center">
                  <div className="check-mark">
                    <i className="bi bi-check-circle-fill"></i>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="sub-title">{t("feedback")} </div>
                <div className="sub-content">
                  {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                      <button
                        key={index}
                        className={
                          index <= rating ? "star-button-on" : "star-button-off"
                        }
                      >
                        <i class="bi bi-star-fill"></i>
                      </button>
                    );
                  })}
                </div>
              </div>
              {comment && (
                <div className="d-flex mt-2 justidy-content-between">
                  <div className="sub-title">{t("comment")} </div>
                  <div className="sub-content w-100">{comment}</div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
