import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SocketContext } from "../context/socket";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import Upload from "rc-upload";
import { toast } from "react-toastify";
import {
  addUserToLocalStorage,
  removeUserFromLocalStorage,
} from "../utils/localStorage";
import { getUserFromLocalStorage } from "../utils/localStorage";
import { deleteAllTasks } from "../redux/taskSlice";
import { deleteCurrentTask } from "../redux/currentSlice";
import { API_URL, CURRENCY } from "../constants/constants";
import { login } from "../redux/loginSlice";
import "../assets/css/Sidebar.css";
import "../assets/css/SidebarMini.css";
import api from "../utils/axios";
import Logo from "../assets/images/logo.png";
import LogoSofa from "../assets/images/sofa.png";
import { useTranslation } from "react-i18next";

export default function Sidebar() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const socket = useContext(SocketContext);
  const name = useSelector((state) => state.login.name);
  const balance = useSelector((state) => state.login.balance);
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState({});
  const tasks = useSelector((state) => state.task);
  const [unread, setUnread] = useState(0);
  const [miniMode, setMiniMode] = useState(false);

  const [tab, setTab] = useState("chat");

  // Calculate Unread Number

  useEffect(() => {
    const getData = async () => {
      const tempUser = getUserFromLocalStorage();
      if (!tempUser) navigate("/");
      else {
        setUser(tempUser);
        setAvatar(tempUser.avatar);
        dispatch(login(tempUser));
        // Get user information again from id  for checking token valid.
        await api
          .get(`${API_URL}/api/v1/user/`)
          .then((res) => {})
          .catch((err) => {
            if (err.response.status == 401) {
              navigate("/");
            }
            const errors = err.response.data.errors;
            errors.forEach((error) => toast.error(error.msg));
          });
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const tempUser = getUserFromLocalStorage();
    if (!tempUser) navigate("/");
    else {
      let unread = 0;
      for (let i = 0; i < tasks.length; i++) {
        for (let j = 0; j < tasks[i].unread.length; j++)
          if (tasks[i].unread[j].user == tempUser._id)
            unread += tasks[i].unread[j].number;
      }
      setUnread(unread);
    }
  }, [tasks]);

  useEffect(() => {
    const pathname = location.pathname;

    switch (pathname) {
      case "/chat":
        setTab("chat");
        break;
      case "/billing":
        setTab("billing");
        break;
      default:
        setTab("");
    }
  }, []);

  // Log out
  const logOut = () => {
    socket.emit("logout");
    // socket.off("setup", user._id);
    dispatch(deleteAllTasks());
    dispatch(deleteCurrentTask());
    removeUserFromLocalStorage();
    navigate("/");
  };

  const handleUploadClick = (e) => {
    e.preventDefault();
  };

  const handleUploadProgress = (e) => {};

  const handleUploadSuccess = async (body, file) => {
    api
      .post(`${API_URL}/api/v1/user/avatar`, { avatar: body })
      .then((res) => {
        let user = getUserFromLocalStorage();
        user.avatar = body;
        addUserToLocalStorage(user);
        setAvatar(body);
        toast.success("Changed Successfully");
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const handleUploadError = (e, body) => {
    toast.error(body.error);
  };

  return (
    <>
      {miniMode ? (
        <div className="sidebar-mini">
          <div className="text-center">
            <div className="image-container">
              <button
                className="mini-mode-button w-100"
                onClick={() => setMiniMode(!miniMode)}
              >
                <i class="bi bi-chevron-double-right"></i>
              </button>
              <img className="mt-5" src={LogoSofa} width="80%" />
              <div className="position-relative">
                <Upload
                  name="image"
                  onClick={handleUploadClick}
                  onProgress={handleUploadProgress}
                  onSuccess={handleUploadSuccess}
                  onError={handleUploadError}
                  action={`${API_URL}/api/v1/upload/cloudinary/avatar`}
                  method="post"
                >
                  <img
                    className="rounded-circle user-avatar position-relative"
                    src={avatar}
                  />
                </Upload>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center">
            <ul>
              <li
                className={`${
                  tab == "chat" ? "active-sidebar-tab" : "sidebar-tab"
                } d-flex justify-content-center align-items-center`}
                onClick={(e) => {
                  setTab("chat");
                  navigate("/chat");
                }}
              >
                <i className="bi bi-chat-dots-fill"></i>
                {unread > 0 ? (
                  <div className="sidebar-unread">{unread}</div>
                ) : (
                  <></>
                )}
              </li>
              <li
                className={
                  tab == "billing" ? "active-sidebar-tab" : "sidebar-tab"
                }
                onClick={(e) => {
                  setTab("billing");
                  navigate("/billing");
                }}
              >
                <i className="bi bi-currency-dollar"></i>
              </li>
            </ul>
            <div className="d-flex justify-content-center">
              <div className={balance < 0 ? "voucher-nagative" : "voucher"}>
                {user.currency == CURRENCY.USD ? "$" : "€"} {balance.toFixed(2)}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="logout" style={{ listStyle: "none" }}>
              <li className="tab" onClick={logOut}>
                <i className="bi bi-power"></i>
              </li>
            </div>
          </div>
        </div>
      ) : (
        <div className="sidebar">
          <div className="text-center">
            <div className="image-container">
              <button
                className="mini-mode-button w-100"
                onClick={() => setMiniMode(!miniMode)}
              >
                <i class="bi bi-chevron-double-left"></i>
              </button>
              <img className="mt-5" src={Logo} width="100%" />
              <div className="position-relative">
                <Upload
                  name="image"
                  onClick={handleUploadClick}
                  onProgress={handleUploadProgress}
                  onSuccess={handleUploadSuccess}
                  onError={handleUploadError}
                  action={`${API_URL}/api/v1/upload/cloudinary/avatar`}
                  method="post"
                >
                  <img
                    className="rounded-circle user-avatar position-relative"
                    src={avatar}
                  />
                </Upload>
              </div>
            </div>
            <h5 className="mt-3">{user.username}</h5>
          </div>
          <div className="mt-5 text-center">
            <ul>
              <li
                className={`${
                  tab == "chat" ? "active-sidebar-tab" : "sidebar-tab"
                } d-flex justify-content-center align-items-center`}
                onClick={(e) => {
                  setTab("chat");
                  navigate("/chat");
                }}
              >
                <i className="bi bi-chat-dots-fill"></i>CHAT
                {unread > 0 ? (
                  <div className="sidebar-unread">{unread}</div>
                ) : (
                  <></>
                )}
              </li>
              <li
                className={
                  tab == "billing" ? "active-sidebar-tab" : "sidebar-tab"
                }
                onClick={(e) => {
                  setTab("billing");
                  navigate("/billing");
                }}
              >
                <i className="bi bi-currency-dollar"></i>BILLING
              </li>
            </ul>
            <div className="text-center">
              <div className="your-balance mb-2">Your balance</div>
              <div className="d-flex justify-content-center">
                <div className={balance < 0 ? "voucher-nagative" : "voucher"}>
                  {user.currency == CURRENCY.USD ? "$" : "€"}{" "}
                  {balance.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="logout" style={{ listStyle: "none" }}>
              <li className="tab" onClick={logOut}>
                <i className="bi bi-power"></i> LOG OUT
              </li>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
