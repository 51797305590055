import React, { useEffect, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import "../../assets/css/BillingInfo.css";
import api from "../../utils/axios";
import { toast } from "react-toastify";
import { API_URL } from "../../constants/constants";

const Europe_Country_List = [
  "Austria",
  "Belgium",
  "Bulgaria",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
];

export default function BillingInfo() {
  const [data, setData] = useState({
    name: "",
    company: "",
    country: "",
    is_eu: false,
    state: "",
    address: "",
    city: "",
    postal: "",
    vat: "",
    vat_checked: false,
    // invoice: false,
  });

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`${API_URL}/api/v1/billing`)
        .then((res) => {
          const {
            name,
            company,
            country,
            is_eu,
            state,
            address,
            city,
            postal,
            vat,
            vat_checked,
            // invoice,
          } = res.data;
          setData({
            name,
            company,
            country,
            is_eu,
            state,
            address,
            city,
            postal,
            vat,
            vat_checked,
            // invoice,
          });
        })
        .catch((err) => {
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    };

    getData();
  }, []);

  const onChange = (e) => {
    let tempData = { ...data };
    tempData[e.target.name] = e.target.value;
    setData(tempData);
  };

  const onChangeCountry = (name, value) => {
    let tempData = { ...data };
    tempData.is_eu = Europe_Country_List.includes(value);
    tempData[name] = value;
    setData(tempData);
  };

  // const onChangeInvoice = () => {
  //   let tempData = { ...data };
  //   tempData["invoice"] = !tempData["invoice"];
  //   setData(tempData);
  // };

  const onChangeVatCheck = () => {
    let tempData = { ...data };
    tempData["vat_checked"] = !tempData["vat_checked"];
    setData(tempData);
  };

  const onSave = async () => {
    const {
      name,
      company,
      country,
      is_eu,
      state,
      address,
      city,
      postal,
      vat,
      vat_checked,
      // invoice,
    } = data;
    await api
      .post(`${API_URL}/api/v1/billing`, {
        name,
        company,
        country,
        is_eu,
        state,
        address,
        city,
        postal,
        vat,
        vat_checked,
        // invoice,
      })
      .then((res) => {
        toast.success("Completed");
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  return (
    <div className="billing-info">
      <div className="title">Billing Info</div>
      <div className="billing-input-group">
        <div className="subtitle">Full Name</div>
        <input
          value={data.name}
          name="name"
          placeholder="e.g. John Smith"
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle d-flex">
          Company Name &nbsp;
          <div style={{ color: "rgb(255,0,0)" }}>*</div>
        </div>
        <input
          value={data.company}
          name="company"
          placeholder="e.g. Best Company Ever"
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">Country</div>
        <CountryDropdown
          value={data.country}
          onChange={(val) => onChangeCountry("country", val)}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">State/Province/Territory</div>
        <input
          value={data.state}
          name="state"
          placeholder=""
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">Address</div>
        <input
          value={data.address}
          name="address"
          placeholder="Street"
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">City</div>
        <input
          value={data.city}
          name="city"
          placeholder="Vieenna"
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">Postal code</div>
        <input
          value={data.postal}
          name="postal"
          placeholder=""
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">VAT number</div>
        <input value={data.vat} name="vat" placeholder="" onChange={onChange} />
        <div className="d-flex align-items-center mt-2">
          <input
            checked={data.vat_checked}
            name="vatcheck"
            type="checkbox"
            id="vatcheck"
            style={{
              width: "24px",
              height: "24px",
              marginRight: "10px",
              border: "solid 1px #d9ebff !important",
              cursor: "pointer",
            }}
            onChange={onChangeVatCheck}
          />
          <label htmlFor="vatcheck" style={{ cursor: "pointer" }}>
            I m eligble for reverse charge with my VAT Number,
          </label>
        </div>
      </div>
      {/* <div className="billing-input-group">
        <div className="subtitle">Invoices</div>
        <div className="d-flex align-items-center">
          <input
            checked={data.invoice}
            name="invoice"
            type="checkbox"
            id="invoice"
            style={{
              width: "24px",
              height: "24px",
              marginRight: "10px",
              border: "solid 1px #d9ebff !important",
              cursor: "pointer",
            }}
            onChange={onChangeInvoice}
          />
          <label htmlFor="invoice" style={{ cursor: "pointer" }}>
            Yes, email my billing info and original invoices.
          </label>
        </div>
      </div> */}
      <button className="save-btn" onClick={onSave}>
        Save Changes
      </button>
    </div>
  );
}
