import React, { useState, useEffect } from "react";
import "../../assets/css/Deposit.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import api from "../../utils/axios";
import { API_URL, PUBLIC_KEY } from "../../constants/constants";
import CheckoutForm from "./CheckoutForm";
import DepositMan from "../../assets/images/deposit_man.png";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { CURRENCY } from "../../constants/constants";

const stripePromise = loadStripe(PUBLIC_KEY);

export default function Deposit() {
  const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [agree, setAgree] = useState(false);
  const [depositPromoShow, setDepositPromoShow] = useState(true);
  const [user, setUser] = useState({});

  const currency = [
    {
      name: "US Dollar",
      symbol: "$",
      currency: "usd",
    },
    {
      name: "Euro",
      symbol: "€",
      currency: "eur",
    },
  ];

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const [depositAmount, setDepositAmount] = useState(1);

  useEffect(() => {
    const tempUser = getUserFromLocalStorage();
    setUser(tempUser);

    if (tempUser.currency == CURRENCY.USD) setSelectedCurrency(0);
    else setSelectedCurrency(1);
  }, []);

  const onDepositAmount = (e) => {
    const value = Math.max(1, e.target.value);
    setDepositAmount(value);
  };

  const onPay = () => {
    api
      .post(`${API_URL}/api/v1/payment/create-payment-intent`, {
        amount: depositAmount,
        currency: currency[selectedCurrency].currency,
      })
      .then((res) => {
        const clientSecret = res.data.clientSecret;
        setClientSecret(clientSecret);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="deposit">
      <div className="title">Deposit</div>
      <h6 className="mb-4">
        Please fill out the informations in billing details before you make
        deposit.
      </h6>
      {!clientSecret && (
        <div className="row">
          <div className="card col-4">
            <div className="currency d-flex justify-content-between align-items-center">
              Deposit currency
              <select
                disabled="true"
                className="deposit-currency"
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
              >
                {currency.map((item, index) => (
                  <option key={index} value={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              Deposit amount
              <div className="d-flex align-items-center position-relative">
                <input
                  className="deposit-number"
                  type="number"
                  value={depositAmount}
                  onChange={onDepositAmount}
                />
                <div className="currency-symbol">
                  {currency[selectedCurrency].symbol}
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex agree-select">
              <input
                id="agree"
                checked={agree ? true : false}
                onClick={() => setAgree(!agree)}
                type="checkbox"
                className="check"
              />
              <div onClick={() => setAgree(!agree)}>
                I have read and agreed to the{" "}
                <a
                  href="https://codemenschen.at/Allgemeine_Geschaeftsbedingungen.pdf"
                  target="new"
                >
                  Terms and Conditions
                </a>
              </div>
            </div>
            <button
              className="pay w-100"
              onClick={onPay}
              disabled={agree ? false : true}
            >
              Confirm {currency[selectedCurrency].symbol}
              {depositAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </button>
          </div>
          {depositPromoShow ? (
            <div className="deposit-promo-card position-relative col-4">
              <button
                className="close-button"
                onClick={() => setDepositPromoShow(false)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
              <div>If you make a deposit of</div>
              <div className="d-flex align-items-baseline">
                <div className="large-text">
                  {currency[selectedCurrency].symbol}1000
                </div>{" "}
                you'll get
              </div>
              <div className="d-flex align-items-baseline">
                <div className="large-text">
                  {currency[selectedCurrency].symbol}250
                </div>{" "}
                bonus on your balance
              </div>
              <img className="mt-3" src={DepositMan} />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}

      {clientSecret && (
        <div className="card">
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              amount={`${currency[selectedCurrency].symbol}${depositAmount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            />
          </Elements>
        </div>
      )}
    </div>
  );
}
