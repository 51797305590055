import React, { useEffect, useContext } from "react";
import ChatBoxTitle from "./ChatBoxTitle";
import ChatBoxContent from "./ChatBoxContent";
import { SocketContext } from "../../context/socket";

const ChatBox = () => {
  const socket = useContext(SocketContext);

  return (
    <div className="chatbox d-flex flex-column justify-content-start">
      <ChatBoxTitle />
      <ChatBoxContent />
    </div>
  );
};

export default ChatBox;
