import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTask, setTasks } from "../../redux/taskSlice";
import { SocketContext } from "../../context/socket";
import { setCurrentTask } from "../../redux/currentSlice";
import api from "../../../src/utils/axios";
import { API_URL, DEADLINE } from "../../constants/constants";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
} from "../../utils/localStorage";

export default function NewTask({ setShowNewTask }) {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [priority, setPriority] = useState("Normal");
  const [deadline, setDeadline] = useState(DEADLINE.WITHIN_HOURS);
  const [isCreating, setIsCreating] = useState(false);
  const priorityList = ["Low", "Normal", "Urgent"];
  const deadlineList = [
    {
      value: DEADLINE.WITHIN_HOURS,
      text: "Within hours",
    },
    { value: DEADLINE.WITHIN_24HOURS, text: "Within 24 hours" },
    { value: DEADLINE.WITHIN_48HOURS, text: "Within 48 hours" },
  ];
  const titleRef = useRef(null);
  const priorityRef = useRef(null);
  const createbuttonRef = useRef(null);

  const onChange = (e) => {
    setTitle(e.target.value);
  };

  const onCreate = async () => {
    setIsCreating(true);

    let user = getUserFromLocalStorage();
    const isnewuser = user.isnewuser;

    await api
      .post(`${API_URL}/api/v1/task/create`, {
        taskname: title,
        priority: priority,
        deadline: deadline,
      })
      .then((res) => {
        setShowNewTask(false);
        if (user.isnewuser == true) {
          user.isnewuser = false;
          addUserToLocalStorage(user);
        }
        socket.emit("new-task", { task: res.data, isnewuser: isnewuser });
        setIsCreating(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
        setIsCreating(false);
      });

    // dispatch(
    //   addTask({
    //     taskname: title,
    //     cost: "",
    //     duedate: "",
    //     assigneddeveloper: "",
    //     priority: priority,
    //     estimated: false,
    //     workeffort: "",
    //     status: 0,
    //     createdtime: new Date().getTime(),
    //   })
    // );

    // dispatch(setCurrentTask(title));
  };

  const onCancel = () => {
    setShowNewTask(false);
  };

  return (
    <div className="new-task">
      <input
        ref={titleRef}
        value={title}
        className="newtask-title"
        placeholder="Title"
        onChange={onChange}
        onKeyDown={(e) => {
          const { key } = e;
          if (key === "Enter") priorityRef.current.focus();
        }}
      />
      <div className="mt-3 text-title">
        {t("priority")}
        <select
          ref={priorityRef}
          className="priority-select"
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          onKeyDown={(e) => {
            const { key } = e;
            if (key === "Enter") createbuttonRef.current.focus();
          }}
        >
          {priorityList.map((item, index) => {
            return (
              <option key={index} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
      {priority === "Urgent" && (
        <div className="mt-3 text-title">
          {t("how_urgent")}
          <select
            className="priority-select"
            value={deadline}
            onChange={(e) => setDeadline(e.target.value)}
            onKeyDown={(e) => {
              const { key } = e;
              if (key === "Enter") createbuttonRef.current.focus();
            }}
          >
            {deadlineList.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.text}
                </option>
              );
            })}
          </select>
        </div>
      )}
      <div className="d-flex justify-content-end mt-3">
        <button className="cancel-button" onClick={onCancel}>
          {t("cancel")}
        </button>
        {isCreating ? (
          <button className="create-button">Creating...</button>
        ) : (
          <button
            ref={createbuttonRef}
            className="create-button"
            onClick={onCreate}
          >
            {t("create")}
          </button>
        )}
      </div>
    </div>
  );
}
