import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import { SocketContext, socket } from "./context/socket";
import store from "./store";
import Chat from "./components/Chat/Index";
import Login from "./components/Login/Index";
import Register from "./components/Register/Index";
import Billing from "./components/Billing/Index";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./components/Sidebar";
import NotFound from "./components/NotFound";
import PaymentSuccess from "./components/PaymentSuccess";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);
    documentHeight();
  }, []);

  const WithSidebar = () => {
    return (
      // <div className="row height-100">
      //   <div className="col-2">
      //     <Sidebar />
      //   </div>
      //   <div className="col-10 right-sidebar" style={{ position: "relative" }}>
      //     <Outlet />
      //   </div>
      // </div>
      <div className="d-flex height-100 w-100 justify-content-start">
        <div>
          <Sidebar />
        </div>
        <div className="w-100 right-sidebar position-relative">
          <Outlet />
        </div>
      </div>
    );
  };

  const WithoutSidebar = () => {
    return <Outlet />;
  };

  return (
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route element={<WithoutSidebar />}>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/chat" element={<Chat />} />
              {/* <Route path="/billing" element={<Billing />} /> */}
              {/* <Route path="/paymentsuccess" element={<PaymentSuccess />} /> */}
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
          <ToastContainer autoClose={1500} limit={3} />
        </BrowserRouter>
      </Provider>
    </SocketContext.Provider>
  );
}

export default App;
