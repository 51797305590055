import React, { useState, useRef, useEffect } from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import "../../assets/css/BillingHistory.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { toast } from "react-toastify";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import api from "../../utils/axios";
import { API_URL, METHOD, CURRENCY } from "../../constants/constants";
import No_Invoice from "../../assets/images/history_man.png";
import Logo from "../../assets/images/team.png";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { useTranslation } from "react-i18next";

const moment = extendMoment(originalMoment);

export default function BillingHistory() {
  const { t } = useTranslation();
  const pdfExportComponent = useRef(null);
  const contentArea = useRef(null);

  const today = moment();
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState(
    moment.range(today.clone().subtract(7, "days"), today.clone())
  );
  const [data, setData] = useState([]);
  const [filterdData, setFilteredData] = useState([]);

  const [selected, setSelected] = useState(0);
  const [billingInfo, setBillingInfo] = useState({});
  const [taxIsEnabled, setTaxIsEnabled] = useState(true);
  const [user, setUser] = useState();
  const [country, setCountry] = useState("");
  // const [task, setTask] = useState({
  //   taskname: "",
  //   hourlyrate: 0,
  //   workeffort: 0,
  // });
  const ref = useRef(null);

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`${API_URL}/api/v1/payment`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });

      await api
        .get(`${API_URL}/api/v1/billing`)
        .then((res) => {
          const billingInfo = res.data;
          setBillingInfo(billingInfo);
          setCountry(billingInfo.country);
          // if (
          //   billingInfo.country == "" ||
          //   billingInfo.country == "Austria" ||
          //   (billingInfo.is_eu == true && billingInfo.vat_checked == false)
          // )
          //   setTaxIsEnabled(true);
          // else setTaxIsEnabled(false);
        })
        .catch((err) => {
          setTaxIsEnabled(true);
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    };

    const tempUser = getUserFromLocalStorage();
    setUser(tempUser);
    getData();
  }, []);

  useEffect(() => {
    const tempFilterdData = data.filter(
      (item) =>
        new Date(item.createdAt).getTime() >=
          new Date(dateRange.start).getTime() &&
        new Date(item.createdAt).getTime() <=
          new Date(dateRange.end).getTime() + 1000 * 3600 * 24
    );

    if (tempFilterdData.length > 0) {
      setFilteredData(tempFilterdData);
      setSelected(0);
      setTaxIsEnabled(tempFilterdData[0].taxIsEnabled);
    }
  }, [data, dateRange]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  // useEffect(() => {
  //   const getTask = async () => {
  //     if (filterdData.length > 0)
  //       await api
  //         .post(`${API_URL}/api/v1/task/payment`, {
  //           paymentId: filterdData[selected]._id,
  //         })
  //         .then((res) => {
  //           setTask(res.data);
  //         })
  //         .catch((err) => {
  //           const errors = err.response.data.errors;
  //           errors.forEach((error) => toast.error(error.msg));
  //         });
  //   };

  //   if (filterdData.length > 0 && filterdData[selected].method == METHOD.PAY) {
  //     getTask();
  //   }
  // }, [filterdData, selected]);

  const onDateChange = (value) => {
    setDateRange(value);
    console.log(dateRange.start);
  };

  const onToogle = () => {
    setIsOpen(!isOpen);
  };

  const onPreview = async (index) => {
    setTaxIsEnabled(filterdData[index].taxIsEnabled);
    setSelected(index);
  };

  const onDownload = () => {
    savePDF(contentArea.current, {
      paperSize: "A4",
      fileName: filterdData[selected].invoiceId,
    });
  };

  return (
    <div className="billing-history h-100 d-flex jusify-content-start flex-column">
      <div className="title">{t("billing_history")}</div>
      <div>
        <input
          className="date-range-input"
          value={`${new Date(
            dateRange.start
          ).toLocaleDateString()} - ${new Date(
            dateRange.end
          ).toLocaleDateString()}`}
          onClick={onToogle}
          placeholder="Date Range"
          readOnly
        />
      </div>
      <div>
        {isOpen && (
          <div ref={ref} className="d-inline">
            <DateRangePicker
              value={dateRange}
              onSelect={onDateChange}
              singleDateRange={true}
            />
          </div>
        )}
      </div>
      <div className="d-flex">
        <div>
          <table className="billing-history-table">
            <thead>
              <tr>
                <th>{/* <input type="checkbox" className="check" /> */}#</th>
                <th>{t("date")}</th>
                <th>{t("invoice_id")}</th>
                <th>{t("total")}</th>
                <th>{t("preview")}</th>
              </tr>
            </thead>
            <tbody>
              {filterdData.map((item, index) => (
                <tr
                  className={index == selected ? "active" : ""}
                  key={index}
                  onClick={() => onPreview(index)}
                >
                  <td>
                    {/* <input
                      type="checkbox"
                      className="check"
                      onClick={() => onSelect(index)}
                    /> */}
                    {index + 1}
                  </td>
                  <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                  <td>{item.invoiceId}</td>
                  <td>
                    {user.currency == CURRENCY.USD ? "$" : "€"}
                    {item.amount}
                  </td>
                  <td>
                    <button onClick={() => onPreview(index)}>
                      {/* <i className="bi bi-download"></i> */}
                      {index == selected ? (
                        <i class="bi bi-eye-fill"></i>
                      ) : (
                        <i class="bi bi-eye"></i>
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {filterdData.length > 0 && (
          <div className="flex-auto">
            <button className="download-btn" onClick={onDownload}>
              <i className="bi bi-download"></i>&nbsp; Download (
              {filterdData[selected].invoiceId})
            </button>
            <div className="invoice-paper">
              <PDFExport ref={pdfExportComponent} paperSize="A4" margin="1.5cm">
                <div ref={contentArea} className="w-100 invoice">
                  <div className="header d-flex justify-content-between">
                    <div className="title">Invoice</div>
                    <div>
                      <img src={Logo} width="150px" />
                    </div>
                  </div>
                  <hr />
                  <div className="content">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="text-bold">Invoice ID</div>
                        <div>: {filterdData[selected].invoiceId}</div>
                      </div>
                      <div className="d-flex">
                        <div>Invoice has been paid at</div>
                        <div className="text-bold">
                          &nbsp;
                          {new Date(
                            filterdData[selected].createdAt
                          ).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-6">
                        Buyer:
                        <div className="d-flex">
                          <div className="text-bold">Name</div>
                          <div>: {billingInfo.name}</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Address</div>
                          <div>: {billingInfo.address}</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Country</div>
                          <div>: {billingInfo.country}</div>
                        </div>
                      </div>
                      <div className="col-6">
                        Seller:
                        <div className="d-flex">
                          <div className="text-bold">Company</div>
                          <div>: Codemenschen GmbH</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Address</div>
                          <div>: Anton-Hubmann-Platz 1/6, 8077 Gössendorf,</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Country</div>
                          <div>: Österreich</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">UID-Nr</div>
                          <div>: ATU76037423</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Nr</div>
                          <div>: +436504861845</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Email</div>
                          <div>: office@codemenschen.at</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Firmenbuchnummer</div>
                          <div>: FN 543274 h</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">FB-Gericht</div>
                          <div>: Landesgericht für Zivilrechtssachen Graz</div>
                        </div>
                      </div>
                    </div>
                    <div className="billing-table">
                      <table>
                        <thead>
                          <tr>
                            <th width="10%">#</th>
                            <th width="60%">DESCRIPTION</th>
                            {/* <th width="10%">RATE</th> */}
                            <th width="10%">QTY</th>
                            <th width="20%">AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              Wordpress Custom Development from wp-sofa.chat
                            </td>
                            <td>
                              {user.currency == CURRENCY.USD ? "$" : "€"}
                              {taxIsEnabled
                                ? (
                                    (filterdData[selected].amount * 5) /
                                    6.0
                                  ).toFixed(2)
                                : filterdData[selected].amount}
                            </td>
                            {/* <td>{1}</td> */}
                            <td>
                              {user.currency == CURRENCY.USD ? "$" : "€"}
                              {taxIsEnabled
                                ? (
                                    (filterdData[selected].amount * 5) /
                                    6.0
                                  ).toFixed(2)
                                : filterdData[selected].amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end total-billing-table">
                      <table>
                        <tbody>
                          <tr>
                            <td className="text-bold">
                              VAT {taxIsEnabled ? 20 : 0}%
                            </td>
                            <td>
                              {user.currency == CURRENCY.USD ? "$" : "€"}
                              {taxIsEnabled
                                ? (filterdData[selected].amount / 6.0).toFixed(
                                    2
                                  )
                                : 0}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-bold">Total</td>
                            <td>
                              {user.currency == CURRENCY.USD ? "$" : "€"}
                              {filterdData[selected].amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    {taxIsEnabled ? (
                      <div>This invoice contains 20% value added tax</div>
                    ) : (
                      <div>
                        In Austria, not taxable and tax As it is custom
                        software, is another service (provision of Information)
                        before. Since {country} is a third country, the rule
                        applies on the receiver location. The Performance is
                        therefore not taxable sales in Austria
                      </div>
                    )}
                  </div>
                  <div className="footer"></div>
                </div>
              </PDFExport>
            </div>
          </div>
        )}
      </div>
      {filterdData.length == 0 && (
        <div className="d-flex justify-content-center flex-auto no-invoice text-center align-items-center">
          <div>
            <img src={No_Invoice} />
            <div className="no-invoice-text">No invoices yet</div>
          </div>
        </div>
      )}
    </div>
  );
}
