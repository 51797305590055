import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export const messageSlice = createSlice({
  name: "message",
  initialState: [],
  reducers: {
    setMessages: (state, action) => {
      state.splice(0, state.length);
      const messages = [...action.payload];
      for (let i = 0; i < messages.length; i++) state.push(messages[i]);
    },
    addMessage: (state, action) => {
      state.push(action.payload);
    },
    deleteMessage: (state, action) => {
      const messageId = action.payload;
      for (let i = 0; i < state.length; i++) {
        if (state[i]._id == messageId) state[i].message_deleted = true;
      }
    },
    deleteAllMessages: (state) => {
      state.splice(0, state.length);
    },
    translateMessage: (state, action) => {
      const { messageId, message } = action.payload;
      for (let i = 0; i < state.length; i++) {
        if (state[i]._id == messageId) state[i].message = message;
      }
    },
    updateMessage: (state, action) => {
      const { _id, message, images, files, task, sender } = action.payload;
      for (let i = 0; i < state.length; i++) {
        if (
          state[i]._id == null &&
          state[i].message == message &&
          state[i].images.length == images.length &&
          state[i].files.length == files.length &&
          state[i].task == task._id &&
          task._id == state[i].task &&
          sender._id == state[i].sender._id
        ) {
          state[i]._id = _id;
          break;
        }
      }
    },
    updateEditMessage: (state, action) => {
      const { id, message } = action.payload;
      for (let i = 0; i < state.length; i++) {
        if (state[i]._id == id) {
          state[i].message = message;
          state[i].edited = true;
          break;
        }
      }
    },
  },
});

export const {
  setMessages,
  addMessage,
  deleteMessage,
  deleteAllMessages,
  translateMessage,
  updateMessage,
  updateEditMessage,
} = messageSlice.actions;

export default messageSlice.reducer;
