import React from "react";
import UserAvatars from "../../assets/images/people.png";

export default function ChatBoxBackground() {
  return (
    <div className="d-flex justify-content-center chat-background align-items-center text-center">
      <div>
        <div>
          <img src={UserAvatars} />
        </div>
        <div
          style={{
            fontWeight: "500",
            fontSize: "30px",
            lineHeight: "150%",
          }}
        >
          <div className="d-flex justify-content-center">
            <div>To start your</div>
            <div
              style={{
                color: "#2A8BF2",
                marginRight: ".5rem",
                marginLeft: ".5rem",
              }}
            >
              productive
            </div>
            <div>work and</div>
          </div>
          <div className="d-flex justify-content-center">
            <div
              style={{
                color: "#A61893",
                marginRight: ".5rem",
                marginLeft: ".5rem",
              }}
            >
              pleasant
            </div>
            communication — create a task.
          </div>
        </div>
      </div>
    </div>
  );
}
