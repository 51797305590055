import React, { useState } from "react";
import fileDownload from "js-file-download";
import axios from "axios";
import { API_URL } from "../../constants/constants";
import "../../assets/css/File.css";
import { useTranslation } from "react-i18next";

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

export default function Files({ files, type }) {
  const { t } = useTranslation();

  const download = (filename, url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <>
      <div className={type == MY_MESSAGE ? "d-flex justify-content-end" : ""}>
        <div className="message-files d-flex justify-content-end flex-auto">
          <ul className="files flex-auto">
            {files.map((file, index) => (
              <li key={index}>
                <div className="file-pane d-flex flex-column justify-content-between">
                  <div className="flex-auto d-flex flex-column justify-content-between p-1">
                    <div className="filename">{file.filename}</div>
                    <div className="fileicon">
                      <i class="bi bi-file-earmark"></i> {t("file")}
                    </div>
                  </div>
                  <div
                    className="download-button"
                    style={{ background: "transparent" }}
                    onClick={() => download(file.filename, file.url)}
                  >
                    {t("download")}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
