import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../assets/css/Balance.css";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { CURRENCY } from "../../constants/constants";

export default function Balance() {
  const balance = useSelector((state) => state.login.balance);
  const [user, setUser] = useState();

  useEffect(() => {
    const tempUser = getUserFromLocalStorage();
    setUser(tempUser);
  }, []);

  return (
    <div className="balance">
      <div className="title">Your Balance</div>
      <div className="value">
        {user && user.currency == CURRENCY.USD ? "$" : "€"}
        {balance.toFixed(2)}
      </div>
    </div>
  );
}
