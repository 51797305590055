import React, { useState } from "react";
import Sidebar from "../Sidebar";
import "../../assets/css/Billing.css";
import Subscription from "./Subscription";
import Balance from "./Balance";
import BillingInfo from "./BillingInfo";
import BillingHistory from "./BillingHistory";
import Deposit from "./Deposit";

const TAB = {
  BALANCE: 0,
  BILLING_INFO: 1,
  BILLING_HISTORY: 2,
  DEPOSIT: 3,
};

export default function Billing() {
  const [selectedTab, setSelectedTab] = useState(TAB.BALANCE);

  return (
    <div className="billing p-5 d-flex jusify-content-start flex-column">
      <div className="title">Billing</div>
      <div className="tab">
        <ul>
          <li
            className={selectedTab == TAB.BALANCE ? "active" : ""}
            onClick={() => setSelectedTab(TAB.BALANCE)}
          >
            Balance
          </li>
          <li
            className={selectedTab == TAB.BILLING_INFO ? "active" : ""}
            onClick={() => setSelectedTab(TAB.BILLING_INFO)}
          >
            Billing info
          </li>
          <li
            className={selectedTab == TAB.BILLING_HISTORY ? "active" : ""}
            onClick={() => setSelectedTab(TAB.BILLING_HISTORY)}
          >
            Billing history
          </li>
          <li
            className={selectedTab == TAB.DEPOSIT ? "active" : ""}
            onClick={() => setSelectedTab(TAB.DEPOSIT)}
          >
            Deposit
          </li>
        </ul>
      </div>
      <div className="flex-auto overflow-auto">
        {selectedTab == TAB.BALANCE ? <Balance /> : <></>}
        {selectedTab == TAB.BILLING_INFO ? <BillingInfo /> : <></>}
        {selectedTab == TAB.BILLING_HISTORY ? <BillingHistory /> : <></>}
        {selectedTab == TAB.DEPOSIT ? <Deposit /> : <></>}
      </div>
    </div>
  );
}
