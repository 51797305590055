import React from "react";
import "../../assets/css/Subscription.css";

export default function Subscription() {
  return (
    <div className="subscription">
      <div className="title"> Subscription</div>
    </div>
  );
}
