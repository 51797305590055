import React, { useState, useContext, useEffect, useRef, useDeferredValue } from "react";
import Avatar from "react-avatar";
import { SocketContext } from "../../context/socket";
import { useSelector } from "react-redux";
import {
  addEstimate,
  addConfirm,
  addOthersUnread,
} from "../../redux/taskSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Message from "./Message";
import User from "./User";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import api from "../../utils/axios";
import { toast } from "react-toastify";
import { API_URL } from "../../constants/constants";
import Notifier from "react-desktop-notification";
import moment from "moment";
import {
  setMessages,
  addMessage,
  updateEditMessage,
} from "../../redux/messageSlice";
import { addUnread, newMessage } from "../../redux/taskSlice";
import { setCurrentTask } from "../../redux/currentSlice";
import Images from "./Images";
import Files from "./Files";
import UserRead from "./UserRead";
import MessageTime from "./MessageTime";
import { debounce } from "../../utils/common";

let selectedTask = {};
let position = 0;
let msg = "wp-sofa.chat ";

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

export default function MessageBox() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const task = useSelector((state) => state.current);
  const [readStatus, setReadStatus] = useState([]);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const messagesEndRef = useRef(null);
  const messageBoxRef = useRef(null);

  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //   // console.log(messagesEndRef,'messagesEndRef');
  // };

  const scrollToBottom = () => {console.log("data");
      messagesEndRef.current?.scrollIntoView({ behavior: "instant" })
      
  };
  // Set User
  useEffect(() => {
    const user = getUserFromLocalStorage();
    setUser(user);
  }, []);

  const messages = useSelector((state) => state.message);
  const finalMessages = useDeferredValue(messages)

  useEffect(() => {
    selectedTask = task;
  }, [task]);

  // Socket Init
  // useEffect(() => {
  //   socket.on("message-received", async (newMessageReceived) => {
  //     console.log(`Message Received : ${newMessageReceived.task._id}`);

  //     const user = getUserFromLocalStorage();
  //     if (
  //       newMessageReceived.sender._id != user._id &&
  //       (newMessageReceived.task._id != selectedTask._id || !selectedTask._id)
  //     ) {
  //       if (newMessageReceived.message) {
  //         const avatar = newMessageReceived.sender.avatar;
  //         Notifier.start(
  //           `${newMessageReceived.sender.username} - ${newMessageReceived.task.taskname}`,
  //           newMessageReceived.message,
  //           "chat",
  //           `${API_URL}/avatar/${avatar}`
  //         );
  //       }
  //       if (newMessageReceived.message) {
  //         // msg = newMessageReceived.message + "   ";

  //         msg = `There is a new message! Task: ${newMessageReceived.task.taskname}  `;
  //         toast.success(`${newMessageReceived.message}`, { theme: "colored" });
  //       } else toast.success("Image sent");
  //     }

  //     // await api
  //     //   .post(`${API_URL}/api/v1/task/latestMessage`, {
  //     //     taskId: newMessageReceived.task._id,
  //     //     latestMessage: newMessageReceived._id,
  //     //   })
  //     //   .then((res) => {
  //     dispatch(
  //       newMessage({
  //         taskId: newMessageReceived.task._id,
  //         message: newMessageReceived.message,
  //       })
  //     );
  //     // })
  //     // .catch((err) => {
  //     //   if (err.response.status == 401) {
  //     //     navigate("/");
  //     //   }
  //     //   const errors = err.response.data.errors;
  //     //   errors.forEach((error) => toast.error(error.msg));
  //     // });

  //     const taskId = newMessageReceived.task._id;
  //     const userId = user._id;
  //     const senderId = newMessageReceived.sender._id;
  //     dispatch(addOthersUnread({ taskId, userId, senderId }));

  //     if (
  //       !selectedTask._id ||
  //       selectedTask._id != newMessageReceived.task._id
  //     ) {
  //       // await api
  //       //   .post(`${API_URL}/api/v1/task/unread`, {
  //       //     taskId: newMessageReceived.task._id,
  //       //   })
  //       //   .then((res) => {
  //       //     dispatch(addUnread({ task: newMessageReceived.task, user: user }));
  //       //   })
  //       //   .catch((err) => {
  //       //     if (err.response.status == 401) {
  //       //       navigate("/");
  //       //     }
  //       //     const errors = err.response.data.errors;
  //       //     errors.forEach((error) => toast.error(error.msg));
  //       //   });
  //       dispatch(addUnread({ task: newMessageReceived.task, user: user }));
  //     } else {
  //       // setMessages((messages) => [...messages, newMessageReceived]);
  //       dispatch(addMessage(newMessageReceived));
  //       socket.emit("message-read", {
  //         task: newMessageReceived.task,
  //         userId: user._id,
  //       });
  //     }
  //   });

  //   return () => socket.removeListener("message-received");
  // }, []);

  // Reload message when task id is changed
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.get(`${API_URL}/api/v1/message/all/${task._id}`);
        const messages = res.data;
        dispatch(setMessages(messages));
      } catch (err) {
        if (err.response.status === 401) {
          navigate("/");
        }
        setIsLoading(false); // Set isLoading to false in case of an error
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      }
      socket.emit("join-chat", task._id);
    }
// { messages.length > 1 ? setIsLoading(false) : setIsLoading(true) }
    fetchData();

    // const tempReadStatus = task.unread;
    // setReadStatus([...tempReadStatus]);

    // const tempUsers = {};
    // task.users.forEach((user) => {
    //   tempUsers[user._id] = user.avatar;
    //   setUsers(tempUsers);
    // });
  }, [task._id, task.users]);

  // This is for animated title effect
  useEffect(() => {
    scrollTitle();
  }, []);

  const delaytimeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const scrollTitle = () => {
    if (msg != "wp-sofa.chat ") {
      document.title =
        msg.substring(position, msg.length) + msg.substring(0, position);
      position++;
      if (position > msg.length) position = 0;
    }
    window.setTimeout(() => scrollTitle(), 170);
  };

  // Scroll go to bottom
  useEffect(() => {
    if(finalMessages.length > 0){
      const goToBottom =  async() => {
        setIsLoading(false);
        await delaytimeout(300);
        scrollToBottom()
      };
      goToBottom()
  }
    
    // console.log(messages , "msges")
    // messageBoxRef.current.scrollToBottom = messageBoxRef.current.scrollHeight;
  }, [finalMessages]);
  return (
    <div ref={messageBoxRef}>
      {isLoading ? "loading....." : ""}
        {!isLoading && finalMessages &&
        finalMessages.map((message, index) => {
          return (
            <div key={index}>
              {(index == 0 ||
                moment(finalMessages[index - 1].createdAt).format("MMM Do YY") !=
                moment(finalMessages[index].createdAt).format("MMM Do YY")) && (
                  <div className="d-flex justify-content-between m-2">
                    <div className="w-100">
                      <hr />
                    </div>
                    <div className="text-center" style={{ width: "400px" }}>
                      {moment(message.createdAt).format("LL")}
                    </div>
                    <div className="w-100">
                      <hr />
                    </div>
                  </div>
                )}
              {(index == 0 ||
                finalMessages[index - 1].sender._id != message.sender._id) &&
                message.sender._id != user._id ? (
                <User
                  avatar={message.sender.avatar}
                  user={message.sender.username}
                />
              ) : (
                <></>
              )}
              {/* {(index == 0 ||
                messages[index - 1].sender._id != message.sender._id ||
                new Date(message.createdAt) -
                  new Date(messages[index - 1].createdAt) >
                  300000) &&
              message.sender._id != user._id ? (
                <User
                  avatar={message.sender.avatar}
                  user={message.sender.username}
                />
              ) : (
                <></>
              )} */}
              {index == 0 ||
                finalMessages[index - 1].sender._id != message.sender._id ||
                new Date(message.createdAt) -
                new Date(finalMessages[index - 1].createdAt) >
                300000 ? (
                <MessageTime
                  time={new Date(message.createdAt).toLocaleTimeString()}
                  type={
                    message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                  }
                />
              ) : (
                <></>
              )}
              {message.message ? (
                <Message
                  id={message._id}
                  message={message.message}
                  type={
                    message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                  }
                  deleted={message.message_deleted}
                  replied={message.replied}
                  edited={message.edited}
                  replymessage={
                    message.replied
                      ? message.replymessage
                      : {
                        id: "",
                        message: "",
                      }
                  }
                />
              ) : (
                <></>
              )}

              {message.images.length > 0 ? (
                <Images
                  images={message.images}
                  type={
                    message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                  }
                />
              ) : (
                <></>
              )}
              {message.files.length > 0 ? (
                <Files
                  files={message.files}
                  type={
                    message.sender._id == user._id ? MY_MESSAGE : OTHER_MESSAGE
                  }
                />
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-end">
                {task.unread.map((unread) =>
                  user._id != unread.user &&
                    index == messages.length - unread.number - 1 ? (
                    <UserRead image={users[unread.user]} />
                  ) : (
                    <></>
                  )
                )}
              </div>
            </div>
          );
        })}
      <div ref={messagesEndRef}></div>

      {/* <div className="go-to-bottom">
        <button className="go-to-bottom-button" onClick={scrollToBottom}>
          <i className="bi bi-arrow-down-circle"></i>
        </button>
      </div> */}
    </div>
  );
}
