import React, { useEffect, useContext, useState, useRef } from "react";
import { toast } from "react-toastify";
import { SocketContext } from "../../context/socket";
import { useSelector, useDispatch } from "react-redux";
import NewTask from "./NewTask";
import {
  addAssign,
  addConfirm,
  addEstimate,
  addTask,
  addComplete,
  addPay,
  addChangeProgress,
  setTasks,
  addOtherRead,
  addChangeOffer,
  deleteTask,
  removeUnread,
  submitRateTask,
  deleteAllTasks,
} from "../../redux/taskSlice";
import { deleteCurrentTask, setCurrentTask } from "../../redux/currentSlice";
import { useNavigate } from "react-router-dom";
import Task from "./Task";
import api from "../../utils/axios";
import { API_URL, ROLE } from "../../constants/constants";
import "../../assets/css/Tasks.css";
import {
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../utils/localStorage";
import { changeBalance } from "../../redux/loginSlice";
import { current } from "@reduxjs/toolkit";
import Logo from "../../assets/images/sofa.png";
import { useTranslation } from "react-i18next";
import moment from "moment";

let taskLength = 0;
let selectedTask = {};

export default function Tasks() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.task);
  const name = useSelector((state) => state.login.name);
  const [role, setRole] = useState(ROLE.CLIENT);
  const [showNewTask, setShowNewTask] = useState(false);
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const task = useSelector((state) => state.current);

  const statusList = [
    "To Estimate",
    "To Confirm",
    "To Do",
    "In Progress",
    "Completed",
    "Finished",
    "All",
  ];
  const priorityList = ["Low", "Normal", "Urgent", "All"];

  const [status, setStatus] = useState("All");
  const [priority, setPriority] = useState("All");

  const currentTaskRef = useRef(null);

  const [searchTasks, setSearchTasks] = useState([]);

  // This part is for moving to current task
  useEffect(() => {
    const goToTop = async () => {
      console.log("task is changed");
      selectedTask = task;
      await delaytimeout(2000);
      scrollToTop();
    };

    // goToTop();
  }, [task]);

  // Task filtering and ordering
  useEffect(() => {
    taskLength = tasks.length;
    const user = getUserFromLocalStorage();
    let tempTask;
    if (priority !== "All" && status !== "All")
      tempTask = tasks.filter(
        (item) => status == statusList[item.status] && priority == item.priority
      );
    else if (priority !== "All")
      tempTask = tasks.filter((item) => priority == item.priority);
    else if (status !== "All")
      tempTask = tasks.filter((item) => status == statusList[item.status]);
    else tempTask = [...tasks];

    // let unreadTempTask = [];
    // let readTempTask = [];
    // for (let i = 0; i < tempTask.length; i++) {
    //   let readFlag = true;
    //   for (let j = 0; j < tempTask[i].unread.length; j++) {
    //     if (
    //       tempTask[i].unread[j].user == user._id &&
    //       tempTask[i].unread[j].number != 0
    //     )
    //       readFlag = false;
    //   }
    //   if (readFlag) readTempTask.push(tempTask[i]);
    //   else unreadTempTask.push(tempTask[i]);
    // }

    // readTempTask.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    // unreadTempTask.sort(
    //   (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    // );

    // tempTask = readTempTask.concat(unreadTempTask);

    // Sort Task by latest change
    tempTask = tempTask.sort((a, b) => {
      return new Date(b.updatedTime).valueOf() - new Date(a.updatedTime).valueOf();
    });
    // console.log("sorted Task:", tempTask.map(item => ({name: item.taskname, time: item.updatedTime})))
    setSearchTasks(tempTask);

    // Select task part
    // if (
    //   task &&
    //   Object.keys(task).length === 0 &&
    //   Object.getPrototypeOf(task) === Object.prototype &&
    //   tempTask.length > 0
    // ) {
    //   dispatch(setCurrentTask(tempTask[tempTask.length - 1]));
    //   dispatch(
    //     removeUnread({ task: tempTask[tempTask.length - 1], user: user })
    //   );
    //   socket.emit("message-read", {
    //     task: tempTask[tempTask.length - 1],
    //     userId: user._id,
    //   });
    // }
  }, [status, priority, tasks]);

  // useEffect(() => {
  //   socket.on("task-received", (newTaskReceived) => {
  //     const user = getUserFromLocalStorage();
  //     console.log(`Task Received: ${newTaskReceived}`);

  //     toast.info(`Task «${newTaskReceived.taskname}» is created`, {
  //       theme: "colored",
  //     });

  //     dispatch(addTask(newTaskReceived));
  //     // if (taskLength == 0 || newTaskReceived.client == user._id) {
  //     //   dispatch(setCurrentTask(newTaskReceived));
  //     // }
  //     socket.emit("join-chat", newTaskReceived._id);
  //   });

  //   socket.on("task-deleted", (taskDeleteReceived) => {
  //     console.log(`Task Received: ${taskDeleteReceived}`);

  //     toast.info(`Task «${taskDeleteReceived.taskname}» is deleted`, {
  //       theme: "colored",
  //     });

  //     if (selectedTask._id == taskDeleteReceived._id)
  //       dispatch(deleteCurrentTask());
  //     dispatch(deleteTask(taskDeleteReceived._id));
  //   });

  //   socket.on("estimate-received", (newEstimateReceived) => {
  //     console.log(`Estimate : ${newEstimateReceived}`);

  //     toast.info(`Task «${newEstimateReceived.taskname}» is estimated`, {
  //       theme: "colored",
  //     });

  //     dispatch(addEstimate(newEstimateReceived));
  //   });

  //   socket.on("changeoffer-received", (newChangeOfferReceived) => {
  //     console.log(`Change Offer : ${newChangeOfferReceived}`);

  //     toast.info(`Task «${newChangeOfferReceived.taskname}» offer is changed`, {
  //       theme: "colored",
  //     });

  //     dispatch(addChangeOffer(newChangeOfferReceived));
  //   });

  //   socket.on("confirm-received", (newConfirmReceived) => {
  //     console.log(`Confirm : ${newConfirmReceived}`);

  //     toast.info(`Task «${newConfirmReceived.taskname}» is confirmed`, {
  //       theme: "colored",
  //     });

  //     dispatch(addConfirm(newConfirmReceived));
  //   });

  //   socket.on("assign-received", (newAssignReceived) => {
  //     console.log(`Assigned : ${newAssignReceived}`);

  //     toast.info(`Task «${newAssignReceived.taskname}» is assigned`, {
  //       theme: "colored",
  //     });

  //     dispatch(addAssign(newAssignReceived));
  //   });

  //   socket.on("complete-received", (newCompleteReceived) => {
  //     console.log(`Completed : ${newCompleteReceived}`);

  //     toast.info(`Task «${newCompleteReceived.taskname}» is completed`, {
  //       theme: "colored",
  //     });

  //     dispatch(addComplete(newCompleteReceived));
  //   });

  //   socket.on("pay-received", (newPayReceived) => {
  //     console.log(`Paid : ${newPayReceived}`);

  //     const { cost } = newPayReceived;
  //     dispatch(changeBalance(cost));

  //     toast.info(`Task «${newPayReceived.taskname}» is paid`, {
  //       theme: "colored",
  //     });

  //     dispatch(addPay(newPayReceived));
  //   });

  //   socket.on("submitrate-received", (newSubmitRateReceived) => {
  //     console.log(`Submit Rate : ${newSubmitRateReceived}`);

  //     const { cost } = newSubmitRateReceived;
  //     toast.info(`Task «${newSubmitRateReceived.taskname}» is get rate`, {
  //       theme: "colored",
  //     });

  //     dispatch(submitRateTask(newSubmitRateReceived));
  //   });

  //   socket.on("read-received", (data) => {
  //     const { taskId, userId } = data;
  //     dispatch(addOtherRead({ taskId: taskId, userId: userId }));
  //   });

  //   return () => {
  //     socket.removeListener("task-received");
  //     socket.removeListener("task-deleted");
  //     socket.removeListener("estimate-received");
  //     socket.removeListener("changeoffer-received");
  //     socket.removeListener("confirm-received");
  //     socket.removeListener("assign-received");
  //     socket.removeListener("complete-received");
  //     socket.removeListener("pay-received");
  //     socket.removeListener("read-received");
  //     socket.removeListener("submitrate-received");
  //   };
  // }, []);

  // Getting all task
  useEffect(() => {
    async function fetchData() {
      await api
        .get(`${API_URL}/api/v1/task`)
        .then((res) => {
          dispatch(setTasks(res.data));
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }

          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    }
    fetchData();
  }, []);

  // Set role
  useEffect(() => {
    const user = getUserFromLocalStorage();
    if (user) setRole(user.role);
  }, []);

  useEffect(() => {
    // tasks.forEach((item) => {
    //   if (item._id == task._id) dispatch(setCurrentTask(item));
    // });
  }, [tasks]);

  const scrollToTop = () => {
    currentTaskRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const delaytimeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const logout = () => {
    socket.emit("logout");
    // socket.off("setup", user._id);
    dispatch(deleteAllTasks());
    dispatch(deleteCurrentTask());
    removeUserFromLocalStorage();
    navigate("/");
  };

  return (
    <div className="tasks d-flex flex-column">
      <div className="container">
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: "20px" }}
        >
          <img className="logo" src={Logo} />
          <button className="logout" onClick={logout}>
            <i class="bi bi-box-arrow-right"></i>
          </button>
        </div>
        <div className="tasks-title mt-3">{t("tasks")}</div>
      </div>
      <div className="mt-3 container d-flex justify-content-between align-items-center">
        <div className="inprogress d-flex  align-items-center">
          <div
            style={{
              color: "#707C97",
              fontSize: "24px",
              marginRight: "10px",
            }}
          >
            <i className="bi bi-funnel-fill"></i>
          </div>
          <div>
            <select
              className="estimate-select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {statusList.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="d-flex  align-items-center">
          <div
            style={{
              color: "#707C97",
              fontSize: "18px",
              marginRight: "10px",
            }}
          >
            {t("priority")}:
          </div>
          <div>
            <select
              className="estimate-select"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              {priorityList.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="" style={{ overflowY: "auto" }}>
        <div className="container">
          {showNewTask ? <NewTask setShowNewTask={setShowNewTask} /> : <></>}
        </div>
        {showNewTask ? <></> : <></>}
        {searchTasks &&
          searchTasks
            .map((item, index) => (
              <div key={index}>
                {item._id == task._id ? (
                  <div ref={currentTaskRef}></div>
                ) : (
                  <></>
                )}
                <Task key={"task" + index} task={item} />
              </div>
            ))}
      </div>
      {role == 1 ? (
        <div className="container mt-3">
          <button
            className="new-task-button"
            onClick={() => setShowNewTask(true)}
          >
            + {t("new_task")}
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
