import React, { useState } from "react";
import FullScreenImage from "./FullScreenImage";
import { API_URL } from "../../constants/constants";
import "../../assets/css/Image.css";

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

export default function Images({ images, type }) {
  const [fullScreenShow, setFullScreenShow] = useState(false);
  const [image, setImage] = useState("");
  return (
    <>
      <FullScreenImage
        image={image}
        fullScreenShow={fullScreenShow}
        setFullScreenShow={setFullScreenShow}
      />
      <div className={type == MY_MESSAGE ? "d-flex justify-content-end" : ""}>
        <div className="message-images d-flex justify-content-end flex-auto">
          <ul className="images flex-auto">
            {images.map((image, index) => (
              <li key={index}>
                <img
                  src={image}
                  onClick={() => {
                    setImage(image);
                    setFullScreenShow(true);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
