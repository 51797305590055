import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../Sidebar";
import Tasks from "./Tasks";
import { useNavigate } from "react-router-dom";
import { SocketContext } from "../../context/socket";
import ChatBox from "./ChatBox";
import UserAvatars from "../../assets/images/people.png";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import ChatBoxBackground from "./ChatBoxBackground";
import { login } from "../../redux/loginSlice";
import Notifier from "react-desktop-notification";
import { toast } from "react-toastify";
import { API_URL, LANGUAGE } from "../../constants/constants";
import { addUnread, newMessage, addOthersUnread } from "../../redux/taskSlice";
import {
  setMessages,
  addMessage,
  updateEditMessage,
} from "../../redux/messageSlice";
import {
  addAssign,
  addConfirm,
  addEstimate,
  addTask,
  addChangeProgress,
  addComplete,
  addPay,
  setTasks,
  addOtherRead,
  addChangeOffer,
  deleteTask,
  removeUnread,
  submitRateTask,
  deleteAllTasks,
} from "../../redux/taskSlice";
import { deleteCurrentTask, setCurrentTask } from "../../redux/currentSlice";
import { changeBalance } from "../../redux/loginSlice";
import "../../assets/css/Chat.css";
import { useTranslation } from "react-i18next";

let selectedTask = {};
let msg = "wp-sofa.chat ";

export default function Chat() {
  const { t, i18n } = useTranslation();
  const currentTask = useSelector((state) => state.current);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const task = useSelector((state) => state.current);
  const tasks = useSelector((state) => state.task);
  const [language, setLanguage] = useState(0);

  const navigate = useNavigate();

  const languageList = [
    {
      language: t("english"),
      slanguage: "EN",
      value: LANGUAGE.EN,
      code: "en",
    },
    {
      language: t("german"),
      slanguage: "DE",
      value: LANGUAGE.DE,
      code: "de",
    },
    {
      language: "Spanish",
      slanguage: "ES",
      value: LANGUAGE.ES,
      code: "es",
    },
  ];

  useEffect(() => {
    const user = getUserFromLocalStorage("user");
    if (!user) {
      navigate("/");
    } else {
      // This is useful when user move from billing to chat
      // dispatch(login(user));
      socket.emit("setup", user);

      if (typeof user.language !== "undefined") setLanguage(user.language);
      else setLanguage(0);
    }
  }, []);

  useEffect(() => {
    let code;
    if (language === "en") {
      let user = getUserFromLocalStorage();
      user.language = LANGUAGE.EN;
      setLanguage(LANGUAGE.EN);
      code = languageList[LANGUAGE.EN].code;
    } else code = languageList[language].code;
    i18n.changeLanguage(code);
  }, [language]);

  useEffect(() => {
    selectedTask = task;
  }, [task]);

  useEffect(() => {
    tasks.forEach((item) => {
      if (item._id == task._id) dispatch(setCurrentTask(item));
    });
  }, [tasks]);

  // Socket init for message receive
  useEffect(() => {
    socket.on("message-received", async (newMessageReceived) => {
      console.log(`Message Received : ${newMessageReceived.task._id}`);

      const user = getUserFromLocalStorage();
      if (
        newMessageReceived.sender._id != user._id &&
        (newMessageReceived.task._id != selectedTask._id || !selectedTask._id)
      ) {
        if (newMessageReceived.message) {
          const avatar = newMessageReceived.sender.avatar;
          Notifier.start(
            `${newMessageReceived.sender.username} - ${newMessageReceived.task.taskname}`,
            newMessageReceived.message,
            "chat",
            `${API_URL}/avatar/${avatar}`
          );
        }
        if (newMessageReceived.message) {
          // msg = newMessageReceived.message + "   ";

          msg = `There is a new message! Task: ${newMessageReceived.task.taskname}  `;
          toast.success(`${newMessageReceived.message}`, { theme: "colored" });
        } else if (newMessageReceived.images.length > 0)
          toast.success("Image sent");
        else toast.success("File sent");
      }

      // await api
      //   .post(`${API_URL}/api/v1/task/latestMessage`, {
      //     taskId: newMessageReceived.task._id,
      //     latestMessage: newMessageReceived._id,
      //   })
      //   .then((res) => {
      dispatch(
        newMessage({
          taskId: newMessageReceived.task._id,
          message: newMessageReceived.message,
        })
      );
      // })
      // .catch((err) => {
      //   if (err.response.status == 401) {
      //     navigate("/");
      //   }
      //   const errors = err.response.data.errors;
      //   errors.forEach((error) => toast.error(error.msg));
      // });

      const taskId = newMessageReceived.task._id;
      const userId = user._id;
      const senderId = newMessageReceived.sender._id;
      dispatch(addOthersUnread({ taskId, userId, senderId }));

      if (
        !selectedTask._id ||
        selectedTask._id != newMessageReceived.task._id
      ) {
        // await api
        //   .post(`${API_URL}/api/v1/task/unread`, {
        //     taskId: newMessageReceived.task._id,
        //   })
        //   .then((res) => {
        //     dispatch(addUnread({ task: newMessageReceived.task, user: user }));
        //   })
        //   .catch((err) => {
        //     if (err.response.status == 401) {
        //       navigate("/");
        //     }
        //     const errors = err.response.data.errors;
        //     errors.forEach((error) => toast.error(error.msg));
        //   });
        dispatch(addUnread({ task: newMessageReceived.task, user: user }));
      } else {
        // setMessages((messages) => [...messages, newMessageReceived]);
        dispatch(addMessage(newMessageReceived));
        socket.emit("message-read", {
          task: newMessageReceived.task,
          userId: user._id,
        });
      }
    });

    socket.on("message-edited", async (editMessageReceived) => {
      if (editMessageReceived.task._id == selectedTask._id)
        dispatch(
          updateEditMessage({
            id: editMessageReceived._id,
            message: editMessageReceived.message,
          })
        );
    });

    return () => {
      socket.removeListener("message-received");
      socket.removeListener("message-edited");
    };
  }, []);

  // Socket init for task realted part
  useEffect(() => {
    socket.on("task-received", (newTaskReceived) => {
      const user = getUserFromLocalStorage();
      console.log(`Task Received: ${newTaskReceived}`);

      toast.info(`Task «${newTaskReceived.taskname}» is created`, {
        theme: "colored",
      });

      dispatch(addTask(newTaskReceived));
      // if (taskLength == 0 || newTaskReceived.client == user._id) {
      //   dispatch(setCurrentTask(newTaskReceived));
      // }
      socket.emit("join-chat", newTaskReceived._id);
    });

    socket.on("task-deleted", (taskDeleteReceived) => {
      console.log(`Task Received: ${taskDeleteReceived}`);

      toast.info(`Task «${taskDeleteReceived.taskname}» is deleted`, {
        theme: "colored",
      });

      if (selectedTask._id == taskDeleteReceived._id)
        dispatch(deleteCurrentTask());
      dispatch(deleteTask(taskDeleteReceived._id));
    });

    socket.on("estimate-received", (newEstimateReceived) => {
      console.log(`Estimate : ${newEstimateReceived}`);

      toast.info(`Task «${newEstimateReceived.taskname}» is estimated`, {
        theme: "colored",
      });

      dispatch(addEstimate(newEstimateReceived));
    });

    socket.on("changeoffer-received", (newChangeOfferReceived) => {
      console.log(`Change Offer : ${newChangeOfferReceived}`);

      toast.info(`Task «${newChangeOfferReceived.taskname}» offer is changed`, {
        theme: "colored",
      });

      dispatch(addChangeOffer(newChangeOfferReceived));
    });

    socket.on("confirm-received", (newConfirmReceived) => {
      console.log(`Confirm : ${newConfirmReceived}`);

      toast.info(`Task «${newConfirmReceived.taskname}» is confirmed`, {
        theme: "colored",
      });

      dispatch(addConfirm(newConfirmReceived));
    });

    socket.on("changeprogress-received", (newChangeProgressReceived) => {
      console.log(`Progress Changed : ${newChangeProgressReceived}`);

      toast.info(
        `Task «${newChangeProgressReceived.taskname}» is done by ${newChangeProgressReceived.percent}%`,
        {
          theme: "colored",
        }
      );

      dispatch(addChangeProgress(newChangeProgressReceived));
    });

    socket.on("assign-received", (newAssignReceived) => {
      const user = getUserFromLocalStorage();
      console.log(`Assigned : ${newAssignReceived}`);

      toast.info(`Task «${newAssignReceived.taskname}» is assigned`, {
        theme: "colored",
      });

      dispatch(addAssign({ task: newAssignReceived, role: user.role }));
    });

    socket.on("complete-received", (newCompleteReceived) => {
      console.log(`Completed : ${newCompleteReceived}`);

      toast.info(`Task «${newCompleteReceived.taskname}» is completed`, {
        theme: "colored",
      });

      dispatch(addComplete(newCompleteReceived));
    });

    socket.on("pay-received", (newPayReceived) => {
      console.log(`Paid : ${newPayReceived}`);

      const { cost } = newPayReceived;
      dispatch(changeBalance(cost));

      toast.info(`Task «${newPayReceived.taskname}» is paid`, {
        theme: "colored",
      });

      dispatch(addPay(newPayReceived));
    });

    socket.on("submitrate-received", (newSubmitRateReceived) => {
      console.log(`Submit Rate : ${newSubmitRateReceived}`);

      const { cost } = newSubmitRateReceived;
      toast.info(`Task «${newSubmitRateReceived.taskname}» is get rate`, {
        theme: "colored",
      });

      dispatch(submitRateTask(newSubmitRateReceived));
    });

    socket.on("read-received", (data) => {
      const { taskId, userId } = data;
      const user = getUserFromLocalStorage();
      if (userId == user._id)
        dispatch(removeUnread({ taskId: taskId, userId }));
      else dispatch(addOtherRead({ taskId: taskId, userId: userId }));
    });

    return () => {
      socket.removeListener("task-received");
      socket.removeListener("task-deleted");
      socket.removeListener("estimate-received");
      socket.removeListener("changeoffer-received");
      socket.removeListener("confirm-received");
      socket.removeListener("changeprogress-received");
      socket.removeListener("assign-received");
      socket.removeListener("complete-received");
      socket.removeListener("pay-received");
      socket.removeListener("read-received");
      socket.removeListener("submitrate-received");
    };
  }, []);

  return (
    <div>
      {currentTask._id ? <ChatBox /> : <Tasks />}

      {/* <div className="col-8 height-100">
        {currentTask._id ? <ChatBox /> : <ChatBoxBackground />}
      </div> */}
    </div>
  );
}
