function getLang() {
    if (navigator.languages != undefined){
        if(navigator.languages[0].includes('-')){
            return navigator.languages[0].split('-')[0];
        }
        return navigator.languages[0];
    }
    if(navigator.language.includes('-'))
        return navigator.language.split('-')[0];
    return navigator.language;
}

function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

export {
    getLang, debounce
}
