import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../../assets/css/Login.css";
import { API_URL } from "../../constants/constants";
import axios from "axios";
import api from "../../utils/axios";
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
} from "../../utils/localStorage";
import { login } from "../../redux/loginSlice";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import Shape1 from "../../assets/images/Shape1.png";
import Shape2 from "../../assets/images/Shape2.png";
import Shape3 from "../../assets/images/Shape3.png";
import LoginImg from "../../assets/images/login.jpg";
import animationData from "../../assets/animation/sammy-line-remote-work-cdm.json";
import Logo from "../../assets/images/sofa.png";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigning, setIsSigning] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const loginbuttonRef = useRef(null);
  const [isRemember, setIsRemember] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };

  useEffect(() => {
    const userValidCheck = async () => {
      api
        .get(`${API_URL}/api/v1/user`)
        .then((res) => {
          navigate("/chat");
        })
        .catch((err) => console.log(err));
    };

    if (!isMobile && !isTablet) {
      window.location.replace("https://wp-sofa.chat");
    } else {
      emailRef.current.focus();
      const user = getUserFromLocalStorage();
      if (user !== null) userValidCheck();
    }
  }, []);

  const onLogin = async () => {
    setIsSigning(true);
    await axios
      .post(`${API_URL}/api/v1/auth/login`, {
        email,
        password,
        isRemember: isRemember,
      })
      .then((res) => {
        setIsSigning(false);
        const data = res.data;
        addUserToLocalStorage(data);
        // dispatch(login(data));
        navigate("/chat");
      })
      .catch((err) => {
        setIsSigning(false);
        emailRef.current.focus();
        const error = err.response.data.errors[0];
        toast.error(error.msg);
      });
  };

  const goToSignup = () => {
    navigate("/register");
  };

  return (
    <div className="login">
      {isMobile ? (
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="container">
            <img className="logo" src={Logo} />
            <div className="title1 mt-3">{t("log_in")}</div>
            <div className="mt-3">
              <div>{t("your_e_mail")}</div>
              <input
                ref={emailRef}
                className="name w-100"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  const { key } = e;
                  if (key === "Enter") passwordRef.current.focus();
                }}
              />{" "}
              <div className="mt-3">
                <div>{t("password")}</div>
                <input
                  ref={passwordRef}
                  type="password"
                  className="name w-100"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === "Enter") loginbuttonRef.current.focus();
                  }}
                />
              </div>
              <div className="mt-4 d-flex">
                <input
                  className="remember"
                  id="rememberme"
                  type="checkbox"
                  checked={isRemember}
                  onClick={() => setIsRemember(!isRemember)}
                />
                <label className="remember-text" htmlFor="rememberme">
                  {t("remember_me")}
                </label>
              </div>
              <div className="mt-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginTop: '6px', lineHeight: '20px' }}>By login you agree to the <a href="terms-of-agreement">terms and conditions</a> of WP-Sofa
                  </div>
                </div>
              </div>
              <div className="text-center">
                {isSigning ? (
                  <button className="join">{t("log_in")}...</button>
                ) : (
                  <button
                    ref={loginbuttonRef}
                    className="join"
                    onClick={onLogin}
                  >
                    {t("log_in")}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="container d-flex text-center justify-content-center mb-5">
            {t("dont_have_account")} &nbsp;
            <button className="gotosignup" onClick={goToSignup}>
              {t("sign_up!")}
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <div className="w-100" style={{ fontSize: "20px" }}>
            Redirect to https://wp-sofa.chat
          </div>
        </div>
      )}
    </div>
  );
}
