import { createSlice } from "@reduxjs/toolkit";
import { useState } from "react";
import { act } from "react-dom/test-utils";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    _id: "",
    email: "",
    username: "",
    role: 1,
    avatar: "",
    balance: 0,
  },
  reducers: {
    logout: (state) => {
      state._id = "";
      state.email = "";
      state.username = "";
      state.role = 1;
      state.avatar = "";
      state.balance = 0;
    },
    login: (state, action) => {
      const { _id, email, username, role, avatar, balance } = action.payload;
      state._id = _id;
      state.email = email;
      state.username = username;
      state.role = role;
      state.avatar = avatar;
      state.balance = balance;
    },
    changeBalance: (state, action) => {
      const balance = action.payload;
      state.balance += balance;
    },
    setBalance: (state, action) => {
      const balance = action.payload;
      state.balance = balance;
    },
  },
});

export const { login, logout, changeBalance, setBalance } = loginSlice.actions;

export default loginSlice.reducer;
