import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./redux/loginSlice";
import taskReducer from "./redux/taskSlice";
import currentReducer from "./redux/currentSlice";
import messageReducer from "./redux/messageSlice";
import messageInputReducer from "./redux/messageInputSlice";

export default configureStore({
  reducer: {
    login: loginReducer,
    task: taskReducer,
    current: currentReducer,
    message: messageReducer,
    messageInput: messageInputReducer,
  },
});
