import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Register.css";
import Form from "react-bootstrap/Form";
import { API_URL, ROLE, CURRENCY } from "../../constants/constants";
import axios from "axios";
import Lottie from "react-lottie";
import Shape1 from "../../assets/images/Shape1.png";
import Shape2 from "../../assets/images/Shape2.png";
import Shape3 from "../../assets/images/Shape3.png";
import { toast } from "react-toastify";
import animationData from "../../assets/animation/sammy-line-remote-work-cdm.json";
import { addUserToLocalStorage } from "../../utils/localStorage";
import Logo from "../../assets/images/sofa.png";
import { useTranslation } from "react-i18next";
import { getLang } from "../../utils/common";
import { supportedLanguages } from "../../constants/languages";

export default function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [role, setRole] = useState(ROLE.CLIENT);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [currency, setCurrency] = useState(CURRENCY.USD);
  const [cPassword, setCPassword] = useState("");
  const [translateLanguage, setTranslateLanguage] = useState(getLang());
  const [country, setCountry] = useState("");
  const [checked, setChecked] = useState(false);
  const [userAgreementDate, setUserAgreementDate] = useState(new Date())

  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const cpasswordRef = useRef(null);
  const clientroleRef = useRef(null);
  const devroleRef = useRef(null);
  const registerbuttonRef = useRef(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  const signup = () => {
    if (!checked) {
      toast.warning("Please agree to our terms of agreement");
      return;
    };
    if (password == cPassword)
      axios
        .post(`${API_URL}/api/v1/auth/register`, {
          username,
          email,
          password,
          role,
          currency,
          translatelanguage: translateLanguage,
          country,
          userAgreementDate
        })
        .then((res) => {
          toast.success("Signup success!");
          addUserToLocalStorage(res.data);
          navigate("/chat");
        })
        .catch((err) => {
          usernameRef.current.focus();
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    else toast.error("Password not match");
  };

  const goBack = () => {
    navigate("/");
  };

  return (
    <div className="register d-flex flex-column justify-content-between">
      <div className="container">
        <img className="logo" src={Logo} />
        <div className="title1 mt-3">{t("sign_up")}</div>
      </div>

      <div className="container flex-auto mt-3" style={{ overflowY: "auto" }}>
        <div>
          <div>{t("your_name")}</div>
          <input
            ref={usernameRef}
            className="name w-100"
            value={username}
            placeholder="John Smith"
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={(e) => {
              const { key } = e;
              if (key === "Enter") emailRef.current.focus();
            }}
          />
        </div>

        <div className="mt-3">
          <div>{t("your_e_mail")}</div>
          <input
            ref={emailRef}
            type="email"
            className="name w-100"
            value={email}
            placeholder="john.smith@example.com"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              const { key } = e;
              if (key === "Enter") passwordRef.current.focus();
            }}
          />
        </div>

        <div className="mt-3">
          <div>{t("create_password")}</div>
          <input
            ref={passwordRef}
            type="password"
            className="name w-100"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              const { key } = e;
              if (key === "Enter") cpasswordRef.current.focus();
            }}
          />
        </div>

        <div className="mt-3">
          <div className="input-text">{t("confirm_password")}</div>
          <input
            ref={cpasswordRef}
            type="password"
            className="name w-100"
            value={cPassword}
            onChange={(e) => setCPassword(e.target.value)}
            onKeyDown={(e) => {
              const { key } = e;
              if (key === "Enter") clientroleRef.current.focus();
            }}
          />
        </div>
        <div className="mt-3">
          <div className="input-text">
            {t("your_language")}
          </div>
          <select
            className="name w-100"
            value={translateLanguage}
            onChange={event => setTranslateLanguage(event.target.value)}
          >
            {supportedLanguages.map((item, index) => {
              return (
                <option key={index} value={item.code}>
                  {item.language}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mt-3">
          <div>{t("currency")}</div>
          <div
            className="d-flex align-items-baseline"
            style={{ marginTop: "10px" }}
          >
            <input
              className="currency-choose"
              ref={clientroleRef}
              checked={currency == CURRENCY.USD ? true : false}
              inline
              id="usd"
              name="group1"
              type="radio"
              onChange={(e) => {
                if (e.target.checked) setCurrency(CURRENCY.USD);
              }}
              onKeyDown={(e) => {
                const { key } = e;
                if (key === "Enter") registerbuttonRef.current.focus();
              }}
            />
            <label className="ms-2" htmlFor="usd">
              {t("usd")}
            </label>
            <input
              className="ms-5 currency-choose"
              ref={devroleRef}
              checked={currency == CURRENCY.EURO ? true : false}
              inline
              id="euro"
              name="group1"
              type="radio"
              onChange={(e) => {
                if (e.target.checked) setCurrency(CURRENCY.EURO);
              }}
              onKeyDown={(e) => {
                const { key } = e;
                if (key === "Enter") registerbuttonRef.current.focus();
              }}
            />
            <label className="ms-2" htmlFor="euro">
              {t("euro")}
            </label>
          </div>
        </div>
        <div className="mt-3">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input style={{
              border: '1px solid #d9ebff',
              borderRadius: '5px',
              width: '24px',
              height: '24px',
              marginRight: '10px',
              cursor: 'pointer'
            }} type="checkbox" value={checked} onChange={() => setChecked(!checked)} />
            <div style={{ marginTop: '6px' }}>By checking this box, I have read and agree to the <a href="terms-of-agreement">terms and agreement </a>(*).</div>
          </div>
        </div>
        <div className="text-center">
          <button ref={registerbuttonRef} className="join" onClick={signup}>
            {t("sign_up")}
          </button>
        </div>
      </div>

      <div className="d-flex text-center justify-content-center mb-5">
        {t("already_have")} &nbsp;
        <button className="gotosignup" onClick={goBack}>
          {t("sign_in!")}
        </button>
      </div>
    </div>
  );
}
