import React, { useEffect, useState, useContext } from "react";
import Popup from "reactjs-popup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast, Flip } from "react-toastify";
import "reactjs-popup/dist/index.css";
import { useTranslation } from "react-i18next";
import api from "../../utils/axios";
import { TRANSLATE_API_KEY, API_URL, ROLE } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteMessage, translateMessage } from "../../redux/messageSlice";
import { SocketContext } from "../../context/socket";
import { setNew, setReply, setEdit } from "../../redux/messageInputSlice";

const MY_MESSAGE = 1;
const OTHER_MESSAGE = 2;

export default function Message({
  id,
  message,
  type,
  deleted,
  replied,
  edited,
  replymessage,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const socket = useContext(SocketContext);

  const [completedMessage, setCompletedMessage] = useState();
  const [completeReplyMessage, setCompletedReplyMessage] = useState("");

  const [editedMessage, setEditedMessage] = useState("");

  useEffect(() => {
    let tempMessage = message.replace(/\r*\n/g, "<br/>");
    tempMessage = linkify(tempMessage);
    setCompletedMessage(tempMessage);
  }, [message]);

  useEffect(() => {
    if (edited) {
      setEditedMessage(
        "<span style='font-style : italic; margin-left: 10px; font-size: 15px'>(edited)</span>"
      );
    } else {
      setEditedMessage("");
    }
  }, [edited]);

  useState(() => {
    if (replied) {
      let tempMessage = "";
      tempMessage +=
        "<div><div><button style='background : transparent; border: none; color : #fff;'><i class='bi bi-arrow-return-left mb-4'></i></button></div>";
      tempMessage +=
        "<div style='font-style : italic; word-break: break-word; overflow: hidden;text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 5; line-clamp: 5; -webkit-box-orient: vertical;'>";
      tempMessage += replymessage.message.replace(/\r*\n/g, "<br/>");
      tempMessage += "</div>";
      tempMessage += "<hr/></div>";
      setCompletedReplyMessage(tempMessage);
    }
  }, [replymessage]);

  const removeMessage = async () => {
    await api
      .post(`${API_URL}/api/v1/message/delete/`, {
        messageId: id,
      })
      .then((res) => {
        dispatch(deleteMessage(res.data._id));
        socket.emit("delete-message", res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }

        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  const linkify = (text) => {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
      return (
        '<a  href="' +
        url +
        '" target="_blank" style="color: #fff">' +
        url +
        "</a>"
      );
    });
  };

  const edit = () => {
    dispatch(setEdit({ id: id, message: message }));
  };

  const reply = () => {
    dispatch(setReply({ id: id, message: message }));
  };

  return (
    // <div className={mine ? "m-3 d-flex justify-content-end" : "m-3"}>
    //   <div className={mine ? "message-me" : "message-user"}>
    //     <div>
    //       <div className={mine ? "message-text-me" : "message-text-user"}>
    //         {message}
    //       </div>
    //       <div className="d-flex justify-content-end">
    //         {time}
    //         {checked ? (
    //           <i className="bi bi-check-all"></i>
    //         ) : (
    //           <i className="bi bi-check"></i>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div
      className={
        type == MY_MESSAGE
          ? "m-1 d-flex justify-content-end"
          : type == OTHER_MESSAGE
          ? "m-1"
          : "m-1 d-flex justify-content-center"
      }
    >
      <div className={type == MY_MESSAGE ? "message-me" : "message-user"}>
        <div className="d-flex">
          {/* <div className="d-flex justify-content-end">{convertedTime}</div> */}
          <div
            dangerouslySetInnerHTML={{
              __html: deleted
                ? "<div style='color: #bbbbbb; font-style: italic;'>Ø message is deleted</div>"
                : completeReplyMessage + completedMessage + editedMessage,
            }}
            className={
              type == MY_MESSAGE
                ? "message-text-me"
                : type == OTHER_MESSAGE
                ? "message-text-user"
                : "message-text-admin"
            }
          ></div>
          {!deleted && (
            <div className="popup-area">
              <Popup
                trigger={
                  <button className="popup-button">
                    <i className="bi bi-three-dots-vertical"></i>
                  </button>
                }
                position={type == MY_MESSAGE ? "bottom right" : "bottom left"}
                arrow={false}
              >
                <div className="menu">
                  {type == MY_MESSAGE && (
                    <div className="menu-item" onClick={edit}>
                      <i className="bi bi-pencil"></i> &nbsp; {t("edit")}
                    </div>
                  )}
                  <CopyToClipboard
                    text={message}
                    onCopy={() =>
                      toast.success("Copied.", {
                        position: "top-center",
                        autoClose: 200,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Flip,
                      })
                    }
                  >
                    <div className="menu-item">
                      <i className="bi bi-stickies"></i> &nbsp; {t("copy")}
                    </div>
                  </CopyToClipboard>
                  <div className="menu-item" onClick={reply}>
                    <i className="bi bi-reply"></i> &nbsp; {t("reply")}
                  </div>
                  {type == MY_MESSAGE && (
                    <div className="menu-item" onClick={removeMessage}>
                      <i className="bi bi-x-square"></i> &nbsp; {t("remove")}
                    </div>
                  )}
                </div>
              </Popup>
            </div>
          )}

          {/* {type != 1 ? (
            <div className="d-flex justify-content-end">
              {time}
              {checked ? (
                <i className="bi bi-check-all"></i>
              ) : (
                <i className="bi bi-check"></i>
              )}
            </div>
          ) : (
            <></>
          )} */}
        </div>
      </div>
    </div>
  );
}
