import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { API_URL } from "../../constants/constants";
import { SocketContext } from "../../context/socket";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { deleteCurrentTask } from "../../redux/currentSlice";
import { deleteAllMessages } from "../../redux/messageSlice";

let otherPersons = [];
let otherOnline = [];
let otherLastSeenTime = [];

export default function ChatBoxTitle() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentTask = useSelector((state) => state.current);
  const [persons, setPersons] = useState([]);
  const [online, setOnline] = useState([]);
  const [lastSeenTime, setLastSeenTime] = useState([]);
  const socket = useContext(SocketContext);

  useEffect(() => {
    const user = getUserFromLocalStorage();
    const users = currentTask.users;
    const tempPerson = users.filter((item) => item._id != user._id);
    setPersons([...tempPerson]);
    otherPersons = [...tempPerson];
    let tempOnline = [];
    let tempLastSeenTime = [];

    for (let i = 0; i < tempPerson.length; i++) {
      tempOnline.push(true);
      tempLastSeenTime.push(new Date());
      socket.emit("is-online", tempPerson[i]._id);
    }

    console.log(tempOnline, tempLastSeenTime);

    setOnline([...tempOnline]);
    setLastSeenTime([...tempLastSeenTime]);
  }, [currentTask._id]);

  // useEffect(() => {
  //   const user = getUserFromLocalStorage();
  //   socket.on("offline-received", (data) => {
  //     const { userId, lastseen } = data;
  //     console.log(`Offline-received ${userId}, ${lastseen}`);
  //     let tempOnline = [...online];
  //     let tempLastSeenTime = [...lastSeenTime];
  //     for (let i = 0; i < otherPersons.length; i++) {
  //       if (otherPersons[i]._id == userId) {
  //         tempOnline[i] = false;
  //         tempLastSeenTime[i] = lastseen;
  //       }
  //     }
  //     console.log(userId, tempOnline);
  //     setOnline([...tempOnline]);
  //     setLastSeenTime([...tempLastSeenTime]);
  //   });

  //   socket.on("online-received", (userId) => {
  //     let tempOnline = [...online];
  //     console.log(tempOnline);
  //     for (let i = 0; i < otherPersons.length; i++) {
  //       if (otherPersons[i]._id == userId) {
  //         tempOnline[i] = true;
  //       }
  //     }
  //     console.log(otherPersons);
  //     console.log(userId, tempOnline);
  //     setOnline([...tempOnline]);
  //   });

  //   return () => {
  //     socket.removeListener("offline-received");
  //     socket.removeListener("online-received");
  //   };
  // }, []);

  useEffect(() => {
    const user = getUserFromLocalStorage();
    socket.on("offline-received", (data) => {
      const { userId, lastseen } = data;

      console.log(`Offline-received ${userId}, ${lastseen}`);
      let tempOnline = [...otherOnline];
      let tempLastSeenTime = [...otherLastSeenTime];
      for (let i = 0; i < otherPersons.length; i++) {
        if (otherPersons[i]._id == userId) {
          tempOnline[i] = false;
          tempLastSeenTime[i] = lastseen;
        }
      }
      console.log(userId, tempOnline);
      // setOnline([...tempOnline]);
      // setLastSeenTime([...tempLastSeenTime]);
      otherOnline = [...tempOnline];
      otherLastSeenTime = [...tempLastSeenTime];
    });

    socket.on("online-received", (userId) => {
      let tempOnline = [...otherOnline];
      console.log(tempOnline);
      for (let i = 0; i < otherPersons.length; i++) {
        if (otherPersons[i]._id == userId) {
          tempOnline[i] = true;
        }
      }
      console.log(otherPersons);
      console.log(userId, tempOnline);
      // setOnline([...tempOnline]);
      otherOnline = [...tempOnline];
    });

    return () => {
      socket.removeListener("offline-received");
      socket.removeListener("online-received");
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setOnline([...otherOnline]);
      setLastSeenTime([...otherLastSeenTime]);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const goBack = () => {
    dispatch(deleteCurrentTask());
    dispatch(deleteAllMessages());
  };

  return (
    <div className="chatbox-title d-flex align-items-center">
      <div className="d-flex justify-content-between align-items-center w-100">
        {/* <div className="d-flex justify-content-between w-100 align-items-center">
          <div className="d-flex align-items-center me-5">
            <div className="position-relative">
              <img src={person.avatar} className="team-avatar" />
              <div
                className={online ? "online-status" : "offline-status"}
              ></div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {person.username}
                </div>
              </div>
              {online ? (
                <></>
              ) : (
                <div className="taskname">
                  Last seen {moment(lastSeenTime).fromNow()}
                </div>
              )}
            </div>
          </div>
          <div>
            <button className="go-back" onClick={goBack}>
              <i className="bi bi-chevron-right"></i>
            </button>
          </div>
        </div> */}
        <div className="justify-content-start">
          {persons.map((person, index) => (
            <div className="d-flex align-items-center me-2 mt-2">
              <div className="position-relative">
                <img src={person.avatar} className="team-avatar" />
                <div
                  className={online[index] ? "online-status" : "offline-status"}
                ></div>
              </div>
              <div>
                <div className="d-flex align-items-center">
                  <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {person.username}
                  </div>
                </div>
                {online[index] ? (
                  <></>
                ) : (
                  <div className="taskname">
                    {t("last_seen")} {moment(lastSeenTime[index]).fromNow()}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div>
          <button className="go-back" onClick={goBack}>
            <i className="bi bi-chevron-right"></i>
          </button>
        </div>
        {/* <div>
          <button className="chatbox-title-right-button">
            <i className="bi bi-three-dots-vertical"></i>
          </button>
        </div> */}
      </div>
    </div>
  );
}
